import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import common from './modules/common'
import home from './modules/home'
import broker from './modules/broker'
import userInfo from './modules/userInfo'
import myCollect from './modules/myCollect'
import order from './modules/order'
import maintainInfo from './modules/maintainInfo'
import patent from './modules/patent'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const persistedstateOptions = {
    storage: window.sessionStorage,
    key: 'brand-source'
}

export default new Vuex.Store({
    modules: {
        common,
        home,
        broker,
        userInfo,
        myCollect,
        order,
        maintainInfo,
        patent
    },
    strict: debug,
    plugins: debug ? [createLogger(), createPersistedState(persistedstateOptions)] : [createPersistedState(persistedstateOptions)]
});
