const coupon = [
	{
		path: '/couponList',
		name: 'couponList',
		component: ()=>import('@/views/coupon/couponList'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '优惠券管理'
		}
	},
	{
		path: '/couponCenter',
		name: 'couponCenter',
		component: ()=>import('@/views/coupon/couponCenter'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '优惠券中心'
		}
	},
]
export default coupon;