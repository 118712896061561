/*
 * @Author: zuochunrui zuochunrui@quandashi.com
 * @Date: 2022-04-18 16:07:37
 * @LastEditors: zuochunrui zuochunrui@quandashi.com
 * @LastEditTime: 2022-05-25 16:56:10
 * @FilePath: \brand-source-web\src\router\routes\release.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const release = [
	{
			path: '/release',
			name: 'release',
			component: () => import(/* webpackChunkName: "home" */ '@/views/release/release'),
			meta: {
				name: '标源_商标买卖_发布商标列表',
				breadCrumbName: '发布商标管理'
			}
	},
	{
		path: '/releasePublish',
		name: 'releasePublish',
		component: () => import(/* webpackChunkName: "home" */ '@/views/release/releasePublish'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '发布商标管理'
		}
	},
	{
		path: '/certificateUpload',
		name: 'certificateUpload',
		component: () => import(/* webpackChunkName: "home" */ '@/views/release/certificateUpload'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '权利证书管理'
		}
	},
	
];

export default release
