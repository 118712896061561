// import * as type from '../mutation-types';

const order = {
  namespaced: true,
  state: {
    orderDetail: {}, // 订单
    buyerAgreement: '',
    sellerAgreement: '',
    hasBuyerFile: false, // 转让协议是否含有买方
    hasSellerFile: false, // 转让协议是否含有卖方
    zrNInfo: {},
    srNInfo: {},
    idCardFront: '', // 法人身份证原件照正面
    idCardBack: '', // 法人身份证原件照反面
    certsGroup: '', // 商标注册证原件扫描件 regno_url
    businessLicense: '', // 营业执照副本复印件
    fileGroup: {}, // 订单详情所有文件集合
    zrfMaterial: [],
    srfMaterial: [],
    qtfMaterial: []
  },
  getters: {},
  mutations: {
    setOrderDetail(state, payload) {
      state.orderDetail = payload;
    },
    setOrderFiles(state, payload) {
      state.fileGroup = payload;
    },
    setBuyerAgreement(state, payload) {
      state.buyerAgreement = payload;
    },
    setSellerAgreement(state, payload) {
      state.sellerAgreement = payload;
    },
    setHasBuyerFile(state, payload) {
      state.hasBuyerFile = payload;
    },
    setHasSellerFile(state, payload) {
      state.hasSellerFile = payload;
    },
    setZrNInfo(state, payload) {
      state.zrNInfo = payload;
    },
    setSrNInfo(state, payload) {
      state.srNInfo = payload;
    },
    setIdCardFront(state, payload) {
      state.idCardFront = payload;
    },
    setIdCardEnd(state, payload) {
      state.idCardBack = payload;
    },
    setRegNo(state, payload) {
      state.certsGroup = payload;
    },
    setLicenseTer(state, payload) {
      state.businessLicense = payload;
    },
    setZrMaterial(state, payload) {
      state.zrfMaterial = payload;
    },
    setSrMaterial(state, payload) {
      state.srfMaterial = payload;
    },
    setQtMaterial(state, payload) {
      state.qtfMaterial = payload;
    }
  },
  actions: {}
};

export default order;
