import * as type from '../mutation-types';
import base from '@/utils/js/base';

let sessionId = base.getCookie('sessionId');

const common = {
  namespaced: true,
  state: {
    niceList: [],
    oldInterfaceParams: {
      appKey: 'quandashi4106565062',
      signMethod: 'md5',
      inputType: 1,
      v: '1.0',
      executor: "140fcf1dfb9b6368d33e1d16d14fcad3",
      format: 'json',
      sessionId: sessionId || ''
    },
    brokerInfo: '',
    allCheckStatus: false,
    allRemoveDulStatus: false,
    userIdentity: {} // 用户信息 包括经纪人类型 0 普通用户，1非认证经纪人 ，2 认证经纪人； 合伙人类型 0个人 1机构； 用户名称； 用户头像
  },
  getters: {},
  mutations: {
    [type.SET_NICE_LIST](state, payload) {
      state.niceList = payload;
    },
    [type.SET_BROKER_INFO](state, payload) {
      state.brokerInfo = payload;
    },
    [type.SET_CHECK_ALL_FLAG](state, payload) {
      state.allCheckStatus = payload;
    },
    [type.SET_CHECK_ALL_REMOVE_DUL_FLAG](state, payload) {
      state.allRemoveDulStatus = payload;
    },
    [type.SET_USER_IDENTITY] (state, payload) {
      state.userIdentity = payload;
    }
  },
  actions: {}
};

export default common;
