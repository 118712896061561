/*
 * @Author: your name
 * @Date: 2022-04-26 13:48:08
 * @LastEditTime: 2022-04-26 14:16:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \brand-source-web\src\store\modules\userInfo.js
 */
import base from "@/utils/js/base";
const userInfo = {
    namespaced: true,
    state: {
        /**
          ("5", "普通用户","user"),
          ("10", "普通自由合伙人（未认证）","hhr_nrz"),
          ("20", "普通自由合伙人（认证）","hhr_rz"),
          ("15", "普通机构主账号（未认证）","org_master_nrz"),
          ("16", "普通机构主账号（认证）","org_master_rz"),
          ("17", "普通机构子账号","org_slave"),
          ("25", "付费自由合伙人（未认证）","pay_hhr_nrz"),
          ("26", "付费自由合伙人（认证）","pay_hhr_rz"),
          ("30", "付费机构主账号（未认证）","pay_org_master_nrz"),
          ("31", "付费机构主账号（认证）","pay_org_master_rz"),
          ("32", "付费机构子账号","pay_org_slave"),
          ("35", "内部认证经纪人","internal_hhr_rz"),
          ("40", "内部管理员","internal_manager");
        */
        agentType: '', // 合伙人类型： 1 机构，0 自由/个人
        partnerType: '', // 经纪人类型：0 普通用户，1非认证经纪人 ，2 认证经纪人
        payStatus: '', // 是否付费: 0未付费 1付费
        interiorStatus: '', // 是否内部合伙人: 1内部 2外部
        accountType: '', // 机构账号类型：1 主账号 0 子账号
        builtinType: '', // 是否内部系统人员(0 否，1是)


        rolePermissionList: [], // 功能列表
        QDS_LOGIN_INFO_OFFICE: '',
        hasLoginInfo: false,
        unreadCount: 0,
        agentInfo: {},
        notifyMsgStatus: {}, // 通知消息状态
        isClient: false, // 是否机构端，控制header
        currentRoute: null, // 当前路由
    },
    getters: {},
    mutations: {
      setAgentType(state, payload) {
        state.agentType = payload
      },
      setPartnerType(state, payload) {
        state.partnerType = payload;
      },
      setPayStatus(state, payload) {
        state.payStatus = payload;
      },
      setInteriorStatus(state, payload) {
        state.interiorStatus = payload;
      },
      setAccountType(state, payload) {
        state.accountType = payload;
      },
      setBuiltinType(state, payload) {
        state.builtinType = payload;
      },


      setRolePermissionList(state, payload) {
        state.rolePermissionList = payload;
      },

      setHasLoginInfo(state, payload) {
        state.hasLoginInfo = payload
      },
      setLoginInfoOffice(state, payload) {
        state.QDS_LOGIN_INFO_OFFICE = payload;
      },
      setUnredCount(state, payload) {
        state.unreadCount = payload;
      },
      setAgentInfo(state, payload) {
        state.agentInfo = payload
      },

      setNotifyMsgStatus(state, payload){
        state.notifyMsgStatus = payload
      },
      setIsClient(state, payload){
        state.isClient = payload
      },

      setCurrentRoute (state, route) {
        state.currentRoute = route
      },
    },
    actions: {}
};

export default userInfo;