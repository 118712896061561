import BaseRequest from "@/utils/http/index";
let request = new BaseRequest('');
let prefix = "/api/source";
let bsPrefix = "/bsapi/source";
let qdsuaa = '/qdsuaa'
let bsapi = "/bsapi";

// 发送验证码
export const sendCode = params => {
  return request.post(qdsuaa + '/uaa/auth/user/sendCode',params,{showGee: true})
}
// 短信验证
export const verifyPhoneCode = params => {
  return request.post(bsapi + '/user/tuser/sendCheck',params)
}
// 生成二维码接口
export const createQrcode = (params) => {
  return request.post('/api/brace/bsMa/wxaCode', params);
};
// 生成小程序登录二维码接口
export const getMiniCode = (params) => {
  return request.post(qdsuaa + '/uaa/auth/mini/getProgramCode', params);
};
// 小程序登录验证回调
export const loopLogin = (params) => {
  return request.post(qdsuaa + '/uaa/auth/user/loopLogin', params);
};
// 验证回调 
export const callBackUrl = params => {
  return request.post(bsapi + '/user/tuser/scanLoginWeb',params)
}
// 账号密码登录
export const loginByPwd = params =>{
  return request.post(qdsuaa +'/uaa/auth/user/loginByPwd',params)
}
export const loginByCode = params =>{
  return request.post(qdsuaa +'/uaa/auth/user/loginByCode',params)
}
// 获取用户信息
export const getPersonInformation = params => {
  return request.post(bsapi + '/user/tuser/personInformation',params)
}
// 获取协议
export const queryAgreementStatus = params => {
  return request.post(bsapi + '/user/queryAgreementStatus',params)
}
// 同意协议
export const userAgree = params => {
  return request.post(bsapi + '/user/userAgree',params);
}
// 协议内容
export const queryAgreements = params => {
  return request.post(bsapi + '/user/queryAgreements',params);
}
// 退出
export const logout = params => {
  return request.post(qdsuaa + '/uaa/auth/user/logout',params)
}
// 根据token获取登录用户信息
export const getLoginInfo = params =>{
  return request.post(qdsuaa +'/uaa/auth/sap/getLoginInfo',params)
}

// 消息中心
// 消息列表
export const getMessageList = params => {
  return request.post(bsapi + '/common/getInnerMsgList',params)
}
// 消息单个删除
export const updateMsgDelete = params => {
  return request.post(bsapi + '/common/updateMsgDelete',params)
}
// 把消息列表设置为已读取
export const innerMsgAllRead = params => {
  return request.post(bsapi + '/common/innerMsgAllRead',params)
}