const nineGridShare = [
  {
    path: '/nineGridShare',
    name: 'nineGridShare',
    component: () => import(/* webpackChunkName: "home" */ '@/views/nineGridShare/nineGridShare'),
    meta: {
      name: '九宫格分享-标源',
      breadCrumbName: '制作九宫格'
    }
  },
];

export default nineGridShare;
