let userCenter = [
  {
		path: '/login',
		name: 'login',
		component: ()=> import('@/views/userCenter/login'),
		meta: {
			name: '用户登录_标源',
      isNoNav: true
		}
	},
	{
		path: '/messageList',
		name: 'messageList',
		component: ()=> import('@/views/userCenter/messageList'),
		meta: {
			name: '权大师_消息通知',
			// isNoNav: true,
			breadCrumbName: '消息'
		}
	},
	{
		path: '/agreementDetail',
		name: 'agreementDetail',
		component: ()=> import('@/views/userCenter/agreementDetail'),
		meta: {
			name: '用户协议',
      isNoNav: true
		}
	},
	{
		path: '/helpCenter',
		name: 'helpCenter',
		component: ()=> import('@/views/userCenter/helpCenter'),
		meta: {
			name: '权大师_帮助中心',
			// isNoNav: true,
			breadCrumbName: '帮助中心'
		}
	},
]
export default userCenter