const brokerHome = [
  {
      path: '/brokerHome',
      name: 'brokerHome',
      component: () => import(/* webpackChunkName: "brokerHome" */ '@/views/brokerHome/index'),
      meta: {
        name: '权大师_标源经纪人主页',
        breadCrumbName: '知产经纪人主页'
      }
  },
  {
    path: '/zlBrokerHome',
    name: 'zlBrokerHome',
    component: () => import(/* webpackChunkName: "zlBrokerHome" */ '@/views/brokerHome4zl/index'),
    meta: {
      name: '权大师_标源经纪人主页',
      breadCrumbName: '知产经纪人主页'
    }
  },
  {
    path: '/comBrokerHome',
    name: 'comBrokerHome',
    component: () => import(/* webpackChunkName: "comBrokerHome" */ '@/views/brokerHomeCommon/index'),
    meta: {
      name: '权大师_标源经纪人主页',
      breadCrumbName: '知产经纪人主页'
    }
  },
  
];

export default brokerHome;
