/*
 * @Author: zuochunrui zuochunrui@quandashi.com
 * @Date: 2022-05-07 10:30:06
 * @LastEditors: zuochunrui zuochunrui@quandashi.com
 * @LastEditTime: 2022-05-07 10:50:12
 * @FilePath: \brand-source-web\src\utils\directives\preventRepeatClick.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  install (Vue) {
    // 防止重复点击
    Vue.directive('preventReClick', {
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            },binding.value || 1000)
          }
        })
      }
    })
  }
}