// 封装请求
import rule from "./rule";
import store from "../../store/index";
import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";
import initGeetest from "@/assets/js/gt";
import Utils from "@/utils/js/Utils";
import base from "@/utils/js/base";
import * as type from "@/store/mutation-types";

const baseUrlMap = new Map([
  ["apre-www.quandashi.com", "https://apre-www.quandashi.com/"],
  ["localhost", `http://${location.host}/`],
  ["172.19.154.128", "http://172.19.154.128:8080/"],
  ["172.19.154.106", "http://172.19.154.106:30012/"],
  ["apre-by.ipjy365.com", "https://apre-by.ipjy365.com/"],
  ["test-by.ipjy365.com", "https://test-by.ipjy365.com/"],
  ["by.ipjy365.com", "https://by.ipjy365.com/"]
]);

//如果有上面的几个走上面几个，没有的话走默认，可扩展性强一些
const rootbBaseUrl = baseUrlMap.get(location.hostname)
  ? baseUrlMap.get(location.hostname)
  : location.hostname.indexOf("apre") > -1
  ? "https://apre-by.ipjy365.com/"
  : "https://by.ipjy365.com/";
// 全局设置
axios.defaults.withCredentials = true;
// axios.defaults.timeout = 100;
const errHandler = msg => {
  msg != "" ? Message.error(msg) : Message.error("服务异常");
};

let MessageBox = null; //定义全局的错误弹窗提示 用于关闭上一个
let timeoutCount = 0;

const createUUId = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

let ipTemp = "";

if (window["returnCitySN"]) {
  ipTemp = window["returnCitySN"].cip;
}
localStorage.setItem("IP_CITY", ipTemp);

export default class BaseRequest {
  constructor(baseURL) {
    this.http = axios.create({
      baseURL: rootbBaseUrl + baseURL
    });
    // 全局默认配置
    this.http.defaults.headers["Authorization"] =
      store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId;
    this.http.interceptors.request.use(
      config => {
        // let tokenValue = window.localStorage.getItem("tokenValue")
        let tokenValue = base.localStorageGet("tokenValue");
        // let tokenName = window.localStorage.getItem("tokenName")
        let tokenName = base.localStorageGet("tokenName");
        let qds_version_id = base.getCookie('qds_version_id');
        config.headers["ip"] = localStorage.getItem("IP_CITY");
        if (tokenName != "") {
          config.headers[tokenName] = tokenValue;
        }
        if (qds_version_id && qds_version_id != "" && qds_version_id != undefined) {
          config.headers['qds_version_id'] = 'v2';
        } else {
          config.headers['qds_version_id'] = '';
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截器
    this.http.interceptors.response.use(
      response => {
        // console.log(response);
        let isFromClient = window.location.href.indexOf('fromPath=qdsClient') > -1 || window.navigator.userAgent.indexOf('Electron') > -1 ? true: false;
        if (response.data.subCode == 10004 || response.data.code == 10004 || (response.data.subCode == 10001 && (response.data.subMessage.indexOf('token')>-1 || response.data.subMessage.indexOf('Token')>-1) )) {
          // Message.error('登录失效，请重新登录')
          let date = new Date();let year = date.getFullYear();let mon = date.getMonth() + 1;let da = date.getDate(); let h = date.getHours(); let m = date.getMinutes(); let s = date.getSeconds();
          let time = `${year}-${mon}-${da} ${h}:${m}:${s}`;
          let uid = base.getCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain) && JSON.parse(decodeURIComponent(base.getCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain))).userId || localStorage.getItem('QDS_LOGIN_INFO_OFFICE_'+document.domain) && JSON.parse(decodeURIComponent(localStorage.getItem('QDS_LOGIN_INFO_OFFICE_'+document.domain))).userId;
          console.log(uid);
          let historyExpiredQdsToken = localStorage.getItem('qds_by_history_expired_token_'+document.domain) && JSON.parse(localStorage.getItem('qds_by_history_expired_token_'+document.domain)) || [];
          if(historyExpiredQdsToken && historyExpiredQdsToken.length >= 20){
            historyExpiredQdsToken.pop();
          }
          let expiredObj = {
            token: localStorage.getItem("tokenValue") && JSON.parse(localStorage.getItem("tokenValue")).ldata || response.config.data && (JSON.parse(response.config.data)).tokenValue || '',
            userId: uid,
            timeStamp: time
          };
          historyExpiredQdsToken.unshift(expiredObj);
          localStorage.setItem('qds_by_history_expired_token_'+document.domain, JSON.stringify(historyExpiredQdsToken));
          localStorage.removeItem("tokenValue");
          localStorage.removeItem("tokenName");
          localStorage.removeItem("QDS_LOGIN_INFO_OFFICE_" + document.domain);
          localStorage.removeItem("QDS_AGENT_ORGAN_INFO_" + document.domain);
          let info = {
            agentOrWriter: "",
            operatorId: "",
            operatorName: "",
            userId: "",
            userImg: "",
            userName: ""
          };
          let storeUserId = store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId;
          store.commit("userInfo/setHasLoginInfo", false);
          
          store.commit('userInfo/setAgentType', -1);
          store.commit('userInfo/setPartnerType', -1);
          store.commit('userInfo/setPayStatus', -1);
          store.commit('userInfo/setInteriorStatus', -1);
          store.commit('userInfo/setAccountType', -1);
          store.commit('userInfo/setBuiltinType', -1);
          
          store.commit("userInfo/setLoginInfoOffice", info);
          store.commit("userInfo/setAgentType", "");
          let clearUserIdentity = {
            agentType: 0,
            avatar: "",
            userName: "",
            userType: 0
          };
          store.commit(`common/${type.SET_USER_IDENTITY}`, clearUserIdentity);
          base.clearCookie("QDS_LOGIN_INFO_OFFICE_" + document.domain);
          base.clearCookie("QDS_AGENT_ORGAN_INFO_" + document.domain);
          base.clearCookie("QDS_COOKIE_" + document.domain);

          let currentRoute = store.state.userInfo && store.state.userInfo.currentRoute ? JSON.parse(store.state.userInfo.currentRoute): null;
          if (store.state.userInfo.isClient || isFromClient) {
            window.parent.postMessage("logout", "*");
          } else {
            if (window.location.href.indexOf("home") > -1) {
              // window.location.reload()
              window.location.href = "/home";
            } else {
              let passRoutes = ['/home','/login','/websiteMaintenance', '/agreementDetail']; // 定义不过校验的路由
              if(!passRoutes.includes(currentRoute.path)){
                sessionStorage.setItem('qdsHistoryUserId', storeUserId);
                sessionStorage.setItem('qdsHistoryRoutePath',currentRoute.path);
                sessionStorage.setItem('qdsHistoryRouteQuery', JSON.stringify(currentRoute.query)!='{}' ? JSON.stringify(currentRoute.query) : '{}');
              }
              window.location.href = "/login";
            }
          }
          return Promise.resolve(response.data);
          // else if(response.data.subCode==10003 && response.data.subMessage.indexOf('GATEWAY_TIMEOUT')>-1){
          //   Message({
          //     message: '请求超时，请稍后重试',
          //     type: "error"
          //   })
          //   return Promise.resolve(response.data);
          // }
        } else {
          let geeTestInterface = [
            "/api/source/brandSourceList",
            "/qdsuaa/uaa/auth/user/loginByPwd"
          ]; // 定义过极验的接口
          // 走极验 这里的处理是只要拦截到接口报A0020就弹极验弹窗，然后验证完成后重新请求失败的接口即可
          if (
            response.data.code == "A0020" &&
            geeTestInterface.includes(response.config.url)
          ) {
            // /api/system/geeTest
            this.post("/bsapi/shield/geeTestClick", {
              ...store.state.common.oldInterfaceParams,
              userId: store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId,
              timestamp: new Date().getTime(),
              sign: new Date().getTime(),
              uniqueCode: createUUId(),
              partnerId: "geetest_pc_search"
            }).then(geeRes => {
              // 调用 initGeetest 进行初始化
              // 参数1：配置参数
              // 参数2：回调，回调的第一个参数验证码对象，之后可以使用它调用相应的接口
              initGeetest(
                {
                  // 以下 4 个配置参数为必须，不能缺少
                  gt: geeRes && geeRes.data && geeRes.data.gt,
                  challenge: geeRes && geeRes.data && geeRes.data.challenge,
                  offline: !geeRes.data.success, // 表示用户后台检测极验服务器是否宕机
                  new_captcha: geeRes && geeRes.data && geeRes.data.new_captcha, // 用于宕机时表示是新验证码的宕机

                  product: "bind", // 产品形式，包括：float，popup
                  width: "300px"
                  // 更多配置参数说明请参见：http://docs.geetest.com/install/client/web-front/
                },
                captchaObj => {
                  let _self = this;
                  captchaObj
                    .onReady(function () {
                      captchaObj.verify();
                    })
                    .onSuccess(function () {
                      let result = captchaObj.getValidate();
                      if (!result) {
                        errHandler("请完成验证");
                        return;
                      }
                      console.log(response);
                      // 校验极验是否验证成功
                      // /api/system/geeTest
                      _self
                        .post("/bsapi/shield/validateGeeTest", {
                          ...store.state.common.oldInterfaceParams,
                          timestamp: new Date().getTime(),
                          sign: new Date().getTime(),
                          challenge: result.geetest_challenge,
                          validate: result.geetest_validate,
                          seccode: result.geetest_seccode,
                          partnerId: "geetest_pc_search",
                          uri:
                            response.config &&
                            response.config.url.replace(/\/api/, ""),
                          uniqueCode: geeRes.data.uniqueCode,
                          userId:
                            response.config &&
                            response.config.headers &&
                            response.config.headers["userId"]
                        })
                        .then(verifyRes => {
                          if (verifyRes.subCode == 10002) {
                            console.log(response.config && response.config.url);
                            if (
                              response.config.url.endsWith(
                                "source/brandSourceList"
                              )
                            ) {
                              let responseObject = JSON.parse(
                                response.config.data
                              );
                              if (
                                responseObject &&
                                responseObject.prefecture == 1
                              ) {
                                store.commit(
                                  "home/setSorceListReqParams",
                                  JSON.parse(response.config.data)
                                );
                              }
                            }
                            window.location.reload();
                            // let queryMethords = response.config.method;
                            // let methodUrl = response.config && response.config.url;
                            // let methodParams = response.config.data;
                            // if (queryMethords == 'post') {
                            //   try {
                            //     _self.post(methodUrl, JSON.parse(methodParams));
                            //   } catch (error) {
                            //     _self.post(methodUrl, methodParams);
                            //   }
                            // }
                            // if (queryMethords == 'get') {
                            //   try {
                            //     _self.post(methodUrl, JSON.parse(methodParams));
                            //   } catch (error) {
                            //     _self.post(methodUrl, methodParams);
                            //   }
                            // }
                          } else {
                            errHandler("校验失败，请完成验证");
                            captchaObj.reset();
                          }
                        });
                    });
                }
              );
            });
          } else {
            return Promise.resolve(response.data);
          }
          // if (response.headers['content-disposition'] && response.headers['content-disposition'].startsWith('attachment')) {
          //   let test = response.data.blob()
          //   console.log('test: ', test);
          //   return false
          // }
          // 走极验 这里的处理是只要拦截到接口报A0020就弹极验弹窗，然后验证完成后重新请求失败的接口即可
          // if (response.data.code == 'A0020') {
          //   // /api/system/geeTest
          //   this.post('/bsapi/shield/geeTestClick', {
          //     ...store.state.common.oldInterfaceParams,
          //     userId: store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId,
          //     timestamp: new Date().getTime(),
          //     sign: new Date().getTime(),
          //     uniqueCode: createUUId(),
          //     partnerId: 'geetest_pc_search'
          //   }).then(geeRes => {
          //     // 调用 initGeetest 进行初始化
          //     // 参数1：配置参数
          //     // 参数2：回调，回调的第一个参数验证码对象，之后可以使用它调用相应的接口
          //     initGeetest({
          //       // 以下 4 个配置参数为必须，不能缺少
          //       gt: geeRes && geeRes.data && geeRes.data.gt,
          //       challenge: geeRes && geeRes.data && geeRes.data.challenge,
          //       offline: !geeRes.data.success, // 表示用户后台检测极验服务器是否宕机
          //       new_captcha: geeRes && geeRes.data && geeRes.data.new_captcha, // 用于宕机时表示是新验证码的宕机

          //       product: "bind", // 产品形式，包括：float，popup
          //       width: "300px"
          //       // 更多配置参数说明请参见：http://docs.geetest.com/install/client/web-front/
          //   }, (captchaObj) => {
          //     let _self = this;
          //     captchaObj.onReady(function () {
          //       captchaObj.verify();
          //     }).onSuccess(function () {
          //       let result = captchaObj.getValidate();
          //       if (!result) {
          //         errHandler('请完成验证');
          //         return;
          //       }
          //       console.log(response);
          //       // 校验极验是否验证成功
          //       // /api/system/geeTest
          //       _self.post('/bsapi/shield/validateGeeTest', {
          //         ...store.state.common.oldInterfaceParams,
          //         timestamp: new Date().getTime(),
          //         sign: new Date().getTime(),
          //         challenge: result.geetest_challenge,
          //         validate: result.geetest_validate,
          //         seccode: result.geetest_seccode,
          //         partnerId: 'geetest_pc_search',
          //         uri: response.config && (response.config.url.replace(/\/api/, '')),
          //         uniqueCode: geeRes.data.uniqueCode,
          //         userId: response.config && response.config.headers && response.config.headers['userId']
          //       }).then((verifyRes) => {
          //         console.log('verifyRes result: !!!!');
          //         console.log(verifyRes);
          //         // verifyRes.code == '00000'
          //         // verifyRes.code == 9091 &&
          //         if (verifyRes.subCode == 10002) {
          //           console.log(response.config && response.config.url);
          //           if (response.config.url.endsWith('source/brandSourceList')) {
          //             let responseObject = JSON.parse(response.config.data);
          //             if (responseObject && responseObject.prefecture == 1) {
          //               store.commit('home/setSorceListReqParams', JSON.parse(response.config.data));
          //             }
          //           }
          //           window.location.reload();
          //           // let queryMethords = response.config.method;
          //           // let methodUrl = response.config && response.config.url;
          //           // let methodParams = response.config.data;
          //           // if (queryMethords == 'post') {
          //           //   try {
          //           //     _self.post(methodUrl, JSON.parse(methodParams));
          //           //   } catch (error) {
          //           //     _self.post(methodUrl, methodParams);
          //           //   }
          //           // }
          //           // if (queryMethords == 'get') {
          //           //   try {
          //           //     _self.post(methodUrl, JSON.parse(methodParams));
          //           //   } catch (error) {
          //           //     _self.post(methodUrl, methodParams);
          //           //   }
          //           // }
          //         } else {
          //           errHandler('校验失败，请完成验证');
          //           captchaObj.reset();
          //         }
          //       });
          //     });
          //   });
          //   });
          // } else if(response.config.showGee){
          //   // /api/system/geeTest
          //   this.post('/bsapi/shield/geeTestClick', {
          //     ...store.state.common.oldInterfaceParams,
          //     userId: store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId,
          //     timestamp: new Date().getTime(),
          //     sign: new Date().getTime(),
          //     uniqueCode: createUUId(),
          //     partnerId: 'geetest_pc_search'
          //   }).then(geeRes => {
          //     // 调用 initGeetest 进行初始化
          //     // 参数1：配置参数
          //     // 参数2：回调，回调的第一个参数验证码对象，之后可以使用它调用相应的接口
          //     initGeetest({
          //       // 以下 4 个配置参数为必须，不能缺少
          //       gt: geeRes && geeRes.data && geeRes.data.gt,
          //       challenge: geeRes && geeRes.data && geeRes.data.challenge,
          //       offline: !geeRes.data.success, // 表示用户后台检测极验服务器是否宕机
          //       new_captcha: geeRes && geeRes.data && geeRes.data.new_captcha, // 用于宕机时表示是新验证码的宕机
          //       product: "bind", // 产品形式，包括：float，popup
          //       width: "300px"
          //       // 更多配置参数说明请参见：http://docs.geetest.com/install/client/web-front/
          //   }, (captchaObj) => {
          //     let _self = this;
          //     captchaObj.onReady(function () {
          //       captchaObj.verify();
          //     }).onSuccess(function () {
          //       let result = captchaObj.getValidate();
          //       if (!result) {
          //         errHandler('请完成验证');
          //         return;
          //       }
          //       // 校验极验是否验证成功
          //       // api/system/geeTest
          //       _self.post('/bsapi/shield/validateGeeTest', {
          //         ...store.state.common.oldInterfaceParams,
          //         timestamp: new Date().getTime(),
          //         sign: new Date().getTime(),
          //         challenge: result.geetest_challenge,
          //         validate: result.geetest_validate,
          //         seccode: result.geetest_seccode,
          //         partnerId: 'geetest_pc_search',
          //         uri: response.config && (response.config.url.replace(/\/api/, '')),
          //         uniqueCode: geeRes.data.uniqueCode,
          //         userId: response.config && response.config.headers && response.config.headers['userId']
          //       }).then((verifyRes) => {
          //         // verifyRes.code == '00000'
          //         // verifyRes.code == 9091 &&
          //         if (verifyRes.subCode == 10002) {
          //           if (response.config.url.endsWith('user/tuser/sendCode')) {
          //             Utils.$emit('startCountdown','param');
          //           }
          //         } else {
          //           errHandler('校验失败，请完成验证');
          //           captchaObj.reset();
          //         }
          //       });
          //     });
          //   });
          //   });
          // } else {
          //   return Promise.resolve(response.data);
          // }
          // let msg = response.data.msg || ''
          // if (response.data.code !== "00000") {
          //   Message.error(msg)
          //   return Promise.reject(response.data);
          // }
          // return Promise.resolve(response.data);
        }
      },
      error => {
        if (
          error.code === "ECONNABORTED" ||
          error.message === "Network Error" ||
          error.message.includes("timeout")
        ) {
          timeoutCount++;
        }
        MessageBox && MessageBox.close;
        if (!MessageBox) {
          MessageBox = timeoutCount > 0 ? MessageBox = Message.error("请求超时，请稍后重试") : Message.error("服务器繁忙(T_T)，请刷新页面");
          timeoutCount = 0;
          // console.log(document.getElementsByClassName('el-loading-mask')[0]);
          // document.getElementsByClassName('el-loading-mask')[0].style.display = 'none';
        }
        return Promise.reject(error);
      }
    );
  }
  put(url, params = {}, config = {}) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    return this.http.put(url, queryParam, config);
  }
  post(url, params = {}, config = {}) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    return this.http.post(url, queryParam, config);
  }
  postPdf(url, params = {}, config = {responseType: 'blob'}) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    return this.http.post(url, queryParam, config);
  }
  postForm(url, params = {}, config) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    return this.http({
      url,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      ...config,
      data: qs.stringify(queryParam)
    });
  }
  delete(url, params = {}, config) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    return this.http({
      url,
      method: "delete",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      ...config,
      queryParam
    });
  }
  get(url, params = {}, config = {}) {
    let baseParam = base.getBaseParam();
    let queryParam = Object.assign({}, baseParam, params)
    let _config = {
      ...config,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      queryParam,
      paramsSerializer: function (queryParam) {
        return qs.stringify(queryParam, {
          arrayFormat: "repeat",
          encode: true
        });
      },
      data: {}
    };
    _config = Object.assign(_config, config) || {};
    return this.http.get(url, _config);
  }
  postMul(url, params, config) {
    let formData = new FormData();
    Object.keys(params).forEach(key => {
      // 为null时 formData会自动转为字符串'null'，所以中间做这层处理
      formData.append(key, params[key] === null ? "" : params[key]);
    });
    if (url.indexOf("upload/getOssFile") > -1) {
      return this.http({
        url,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        responseType: "arraybuffer",
        data: formData,
        ...config
      });
    }
    return this.http({
      url,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData,
      ...config
    });
  }
}
