import Vue from "vue";

// v-dialogDrag: 弹窗拖拽
Vue.directive("dialogDrag", {
	bind(el, binding, vnode) {
		//拿到拖拽元素及弹窗元素
		const dialogHeaderEl = el.querySelector('.el-dialog__header')
		const dragDom = el.querySelector('.el-dialog')
		dialogHeaderEl.style.cssText += ';cursor:move;'
		dragDom.style.cssText += ';top:0px;'

		//给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；
		dragDom.style.overflow = "hidden";
		dragDom.style.userSelect = 'auto';

		//清除选择头部文字效果

		dialogHeaderEl.onselectstart = new Function("return false");

		// 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
		const getStyle = (function() {
			if (window.document.currentStyle) {
				return (dom, attr) => dom.currentStyle[attr]
			} else {
				return (dom, attr) => getComputedStyle(dom, false)[attr]
			}
		})()

		dialogHeaderEl.onmousedown = (e) => {
			// 鼠标按下，计算当前元素距离可视区的距离
			const disX = e.clientX - dialogHeaderEl.offsetLeft
			const disY = e.clientY - dialogHeaderEl.offsetTop

			const dragDomWidth = dragDom.offsetWidth
			const dragDomHeight = dragDom.offsetHeight

			const screenWidth = document.body.clientWidth
			const screenHeight = document.body.clientHeight

			const minDragDomLeft = dragDom.offsetLeft
			const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

			const minDragDomTop = dragDom.offsetTop
			const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight

			// 获取到的值带px 正则匹配替换
			let styL = getStyle(dragDom, 'left')
			let styT = getStyle(dragDom, 'top')

			if (styL.includes('%')) {
				styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
				styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
			} else {
				styL = +styL.replace(/\px/g, '')
				styT = +styT.replace(/\px/g, '')
			}

			document.onmousemove = function(e) {
				// 通过事件委托，计算移动的距离
				let left = e.clientX - disX
				let top = e.clientY - disY

				// 边界处理
				if (-(left) > minDragDomLeft) {
					left = -minDragDomLeft
				} else if (left > maxDragDomLeft) {
					left = maxDragDomLeft
				}

				if (-(top) > minDragDomTop) {
					top = -minDragDomTop
				} else if (top > maxDragDomTop) {
					top = maxDragDomTop
				}

				// 移动当前元素
				dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`

				// emit onDrag event
				vnode.child.$emit('dragDialog')
			}

			document.onmouseup = function(e) {
				document.onmousemove = null
				document.onmouseup = null
			}
		}
	}
});
Vue.directive("commonDrag", {
	//1.指令绑定到元素上回立刻执行bind函数，只执行一次
	//2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
	//3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
	bind: function () {},
	//inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
	inserted: function (el) {
		el.onmousedown = function (e) {
			var disx = e.pageX - el.offsetLeft;
			var disy = e.pageY - el.offsetTop;
			document.onmousemove = function (e) {
				el.style.left = e.pageX - disx + "px";
				el.style.top = e.pageY - disy + "px";
			};
			document.onmouseup = function () {
				document.onmousemove = document.onmouseup = null;
			};
		};
	},
	//当VNode更新的时候会执行updated，可以触发多次
	updated: function () {},
});