<template>
  <div id="rightMenu">
    <div class="menuList">
      <div class="rules">
        <a
          href="https://apre-www.quandashi.com/news/brand-standard"
          style="color: #FFFFFF;"
          target="_blank"
        > 商<br />标<br />注<br />册<br />规<br />则</a>
      </div>
      <div class="menu">
        <div
          id="toTop"
          class="menuItem"
          title="顶部"
          @click="backTop"
        >
          <i class="el-icon-arrow-up icon"></i> 置顶
        </div>
        <div class="menuItem">
          <el-popover
            placement="left"
            width="110"
            trigger="hover"
            popper-class="popperClass"
          >
            <span slot="reference"><i class="icon el-icon-box"></i> <br />小程序</span>
            <div style="text-align: center">
              <img
                src="@/assets/images/menubar/ewm-xcx.jpg"
                alt=""
                style="width: 110px;height: 110px;text-align: center"
              />
            </div>
          </el-popover>
        </div>
        <div class="menuItem">
          <el-popover
            placement="left"
            width="110"
            trigger="hover"
            popper-class="popperClass"
          >
            <span slot="reference"><i class="el-icon-mobile-phone icon"></i> APP</span>
            <div style="text-align: center">
              <img
                src="@/assets/images/menubar/ewm-app.png"
                alt=""
                style="width: 110px;height: 110px;text-align: center"
              />
            </div>
          </el-popover>
        </div>
        <div class="menuItem">
          <el-popover
            placement="left"
            width="110"
            trigger="hover"
            popper-class="popperClass"
          >
            <span slot="reference"><i class="icon el-icon-box"></i> <br />公众号</span>
            <div style="text-align: center">
              <img
                src="@/assets/images/menubar/ewm-fwh.png"
                alt=""
                style="width: 110px;height: 110px;text-align: center"
              />
            </div>
          </el-popover>
        </div>
        <div class="menuItem">
          <el-popover
            placement="left-end"
            width="210"
            trigger="hover"
            popper-class="popperClassSuggest"
          >
            <span slot="reference"><i class="el-icon-chat-line-square icon"></i> <br /> 咨询<br />反馈</span>
            <div class="suggest">
              <div class="sug">
                <div><img src="@/assets/images/menubar/icon-phone.png" /></div>
                <div>
                  <p class="title">
                    售前咨询
                  </p>
                  <p class="subtitle">
                    商标、专利、版权服务
                  </p>
                </div>
              </div>
              <div class="sug">
                <div><img src="@/assets/images/menubar/icon-service.png" /></div>
                <div>
                  <p class="title">
                    售后服务
                  </p>
                  <p class="subtitle">
                    业务进度、官文与发票业务
                  </p>
                </div>
              </div>
              <div class="sug">
                <div><img src="@/assets/images/menubar/icon-partner.png" /></div>
                <div>
                  <p class="title">
                    合伙人专线
                  </p>
                  <p class="subtitle">
                    合伙人服务专线
                  </p>
                </div>
              </div>
              <div class="sug">
                <div><img src="@/assets/images/menubar/icon-hotline.png" /></div>
                <div>
                  <p class="title">
                    服务热线
                  </p>
                  <p
                    class="subtitle"
                    style="color: #FF7200;"
                  >
                    400-8000-211
                  </p>
                </div>
              </div>
              <div class="sug">
                <div><img src="@/assets/images/menubar/icon-suggestion.png" /></div>
                <div>
                  <p class="title">
                    客服意见
                  </p>
                  <p class="subtitle">
                    投诉、建议
                  </p>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuBar",
  data(){
    return{
      scrollTop: 0,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    scrollToTop(el) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;

    },
    backTop(){
      var that = this;
      let timer = setInterval(()=>{
        let speed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollToTop +speed;
        if(that.scrollTop === 0){
          clearInterval(timer)
        }
      },16)
    }
  }
}
</script>

<style lang="scss">
.popperClass{min-width: 110px!important;}
.popperClassSuggest{
  .sug{
    display: flex;
    text-align: left;
    padding-top: 15px;
    div:first-child{width: 35px;padding-left: 10px;}
    img{
      width: 16px;
      padding-top: 5px;
    }
    .title{
      height: 21px;
      line-height: 21px;
      color: #333;
      font-size: 16px;
    }
    .subtitle{
      height: 16px;
      line-height: 16px;
      color: #999;
      font-size: 10px;
    }
  }
  .sug:last-child{padding-bottom: 15px;}
}
#rightMenu {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 42px;
  text-align: center;
  font-size: 12px;
  z-index: 2;
  .menuList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;

    .rules{
      margin-bottom: 25px;
      word-break: break-word;
      background: url("../assets/images/menubar/plus-ad.png") no-repeat center;
      background-size: cover;
      color: white;
      font-size: 14px;
      height: 110px;
      padding: 5px 0;
      cursor: pointer;
      line-height: 18px;
    }
    .menu {
      background: #ffffff;
      padding: 20px 0;
      .menuItem{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        cursor: pointer;
        color: #222222;

      }
      .menuItem:not(:first-child):hover,.menuItem:not(:first-child):hover .icon{color: #FF7200;}
      .icon{font-size: 22px;margin-bottom: 5px;color: #a3a3a3;}
      div:last-child{margin-bottom: 0}
    }
  }
}
</style>