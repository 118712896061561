const myCollect = [
  {
      path: '/myBrowseRecord',
      name: 'myBrowseRecord',
      component: () => import(/* webpackChunkName: "myCollect" */ '@/views/myBrowseRecord/index'),
      meta: {
        name: '标源_商标买卖',
        breadCrumbName: '浏览记录'
      }
  }
];

export default myCollect;
