import * as type from '../mutation-types';

const patent = {
    namespaced: true,
    state: {
        zlBatchShare: false,
        zlAllSelect: false, // 底部checkbox是否全选
        zlSelectCount: 0, // 已选多少商标
        zlSelectPatentArr: [], // 已选的商标

        
        clearSingleBrand: false,
        showUnloginDialog: false,
        negotiableFlag: false,
        zlHideExportBtn: false,
        showShareOriginFlag: false,
        sourceListReqParams: {}
    },
    getters: {},
    mutations: {
        [type.SET_ZL_BATCH_SHARE](state, payload) {
            state.zlBatchShare = payload;
        },
        [type.SET_ZL_ALL_SELECT](state, payload) {
            state.zlAllSelect = payload;
        },
        [type.SET_ZL_SELECT_COUNT](state, payload) {
            state.zlSelectCount = payload;
        },
        [type.SET_SELECTED_PATENT_ARR](state, payload) {
            state.zlSelectPatentArr = payload;
        },
        [type.CLEAR_SINGLE_BRAND](state, payload) {
            state.clearSingleBrand = payload;
        },
        [type.SHOW_UNLOGIN_DIALOG](state, payload) {
            state.showUnloginDialog = payload;
        },
        [type.SET_NEGOTIABLE](state, payload) {
            state.negotiableFlag = payload;
        },
        [type.SET_ZL_HIDE_EXPORT_BTN](state, payload) {
            state.zlHideExportBtn = payload;
        },
        [type.SET_ORIGIN_URL] (state, payload) {
            state.showShareOriginFlag = payload;
        },
        setSorceListReqParams (state, payload) {
            state.sourceListReqParams = payload;
        }
    },
    actions: {}
};

export default patent;
