<template>
  <div class="select-all-footer">
    <div
      class="footer-cont"
      :style="{width: isClient ? '100%' : ''}"
    >
      <div class="cont-check">
        <!-- <el-checkbox
          v-model="allChecked"
          @change="allSelectChanged"
        ></el-checkbox>
        全选 -->
      </div>
      <div class="cont-payload"></div>
      <div class="cont-right">
        <div class="cont-desc">
          <!-- 无底价标源将无法改价，分享用户将展示原价 -->
        </div>
        <div class="cont-checked">
          已选商标 <span>{{ selectCount }}</span> 个
        </div>
        <div
          v-if="!hideExportBtn"
          class="btn-export"
          @click="openExportSettingDialog"
        >
          导出Excel
        </div>
        <div
          v-if="myCollectBatch"
          class="btn-export"
          @click="handleCancleCollect"
        >
          取消收藏
        </div>
        <div
          v-if="brokerBatch || myCollectBatch"
          class="btn-export"
          @click="handleAddReconmmend"
        >
          加入推荐
        </div>
        <div
          class="btn-nextStep"
          @click="handlePriceChange"
        >
          改价分享
        </div>
        <div
          class="btn-nextStep"
          style="margin-left: 20px;"
          @click="toNineGridPage"
        >
          制作九宫格
        </div>
        <div
          class="btn-export"
          style="margin-left: 20px;margin-right: 0;"
          @click="initHomeStoreState"
        >
          清空
        </div>
      </div>
    </div>
    <!-- 扫码改价分享 -->
    <change-price-to-share
      :show-dialog.sync="sharePriceVisible"
      :multi-brand="selectedBrandList"
      @closeShare="handleCloseShareDialog"
    ></change-price-to-share>

    <el-dialog
      v-dialogDrag
      title="导出配置"
      :visible.sync="showExportSettingDialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="460px"
      class="showExportSettingDialog"
      center
    >
      <div class="showExportSettingContent">
        <div class="info">
          <div class="info-left">
            导出选项：
          </div>
          <div class="info-right">
            <el-checkbox
              v-model="exportSettings.checkPrice"
              size="small"
            >
              导出价格
            </el-checkbox>
            <el-checkbox
              v-model="exportSettings.checkImage"
              size="small"
            >
              导出图样
            </el-checkbox>
          </div>
        </div>
        <div class="info">
          <div class="info-left">
            调整价格：
          </div> 
          <div class="info-right">
            在市场价基础上加价
          </div>
        </div>
        <div
          class="info"
          style="position: relative;top: -10px;"
        >
          <div class="info-left"></div> 
          <div class="info-right">
            <el-radio-group
              v-model="exportSettings.activePriceName"
              size="small"
              :disabled="!exportSettings.checkPrice"
            >
              <el-radio-button label="2">
                百分比
              </el-radio-button>
              <el-radio-button label="1">
                固定值
              </el-radio-button>
            </el-radio-group>
            <el-input
              v-model="exportSettings.range"
              oninput="value=value.replace(/[^\d]/g,'')"
              :disabled="!exportSettings.checkPrice"
              @input="pricePercentChange"
            ></el-input>
            <span>&nbsp;&nbsp;{{ exportSettings.activePriceName=='2' ? '%' : '元' }}</span>
          </div>
        </div>
        <div class="footer">
          <el-button
            v-preventReClick="2000"
            size="small"
            class="exportBtn"
            :disabled="disabledBtn"
            @click="exportExcel"
          >
            确认
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import changePriceToShare from '@/components/changePriceToShare';
import * as type from '@/store/mutation-types';
import { cancelCollection } from "@/apis/myCollect/myCollect";
import { addRecommend,exportBrandSourceCell } from '@/apis/home/home';
import { clickRecord } from '@/apis/broker/patentBroker';

/**
 * 底部分享组件
 */
export default {
  components: {
    changePriceToShare,
  },
  data() {
    return {
      allChecked: false,
      sharePriceVisible: false,
      selectedBrandList: [],
      exportArrIds: [],
      showExportSettingDialogVisible: false,
      exportSettings: {
        activePriceName: '2',
        range: '0',
        checkPrice: true,
        checkImage: true,
      },
      disabledBtn: false,
    }
  },
  computed: {
    ...mapState('home', {
      selectCount: state => state.selectCount,
      selectBrandArr: state => state.selectBrandArr,
      allSelect: state => state.allSelect,
      hideExportBtn: state => state.hideExportBtn
    }),
    ...mapState('broker', {
      brokerBatch: state => state.brokerBatch // 经纪人首页在售标源推荐标源的批量操作
    }),
    ...mapState('myCollect', {
      myCollectBatch: state => state.myCollectBatch // 我的收藏底部展示
    }),
    ...mapState('common', {
      niceList: state => state.niceList,
      allCheckStatus: state => state.allCheckStatus
    }),
    ...mapState('userInfo', {
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      isClient: state => state.isClient
    }),
  },
  watch: {
    allCheckStatus(val) {
      this.allChecked = val;
    },
    'exportSettings.activePriceName'(val){
      this.exportSettings.range = 0
    }
  },
  methods: {
    // 全选checkbox change
    allSelectChanged(val) {
      this.$store.commit(`home/${type.SET_ALL_SELECT}`, val);
    },
    // 打开导出配置弹窗
    openExportSettingDialog(){
      this.exportArrIds = [];
      if (this.selectBrandArr.length == 0) {
        this.$toast.showToast('请选择商标后点击');
        return;
      }
      if (this.selectBrandArr.length > 600) {
        this.$toast.showToast('最多选择600个商标');
        return;
      }
      this.selectBrandArr.forEach(item => {
        this.exportArrIds.push(item.id);
      })
      this.showExportSettingDialogVisible = true;
    },
    pricePercentChange(val){
      if (this.exportSettings.activePriceName == "2" && Number(val) > 20) {
        this.exportSettings.range = 20;
      } else if (this.exportSettings.activePriceName == "1" && Number(val) > 100000) {
        this.exportSettings.range = 100000;
      }
    },
    exportExcel(){
      var that = this;
      if(this.exportSettings.checkPrice && this.exportSettings.activePriceName==2 && Number(this.exportSettings.range) > 20){
        this.$toast.showToast('加价百分比最多为20%')
        return
      }
      if(this.exportSettings.checkPrice && this.exportSettings.activePriceName==1 && Number(this.exportSettings.range) > 100000){
        this.$toast.showToast('加价固定值最多为100,000元')
        return
      }
      console.log(this.exportSettings);
      // 埋点
      let ipStaParam = {
        appKey: 'quandashi4106565062',
        signMethod: 'md5',
        inputType: 1,
        v: '1.0',
        executor: "140fcf1dfb9b6368d33e1d16d14fcad3",
        inputType: 1,
        format: 'json',
        timestamp: new Date().getTime(),
        sign: new Date().getTime(),

        userId: this.userInfo.userId,
        userIdStr: this.userInfo.userId || '0',
        projectCode: '144117',
        projectName: 'Excel导出',
        source: 4,
        requestUlr: `${this.$route.path}`
      }
      clickRecord(ipStaParam).then(res=>{
      })

      console.log(this.selectBrandArr);
      let esIds = [];
      this.selectBrandArr.forEach(item=>{
        esIds.push(item.id);
      })
      this.$notify({
        title: "数据处理中",
        message: "即将下载...",
        type: "success"
      });
      this.disabledBtn = true;
      let params = {
        agentId: this.userInfo.userId,
        esIds: esIds,
        exportPrice: this.exportSettings.checkPrice ? 1 : 0,
        exportImage: this.exportSettings.checkImage ? 1 : 0,
        exportPriceType: this.exportSettings.activePriceName,
        markupPrice: this.exportSettings.activePriceName==1 ? this.exportSettings.range || null : null,
        markupScale: this.exportSettings.activePriceName==2 ? this.exportSettings.range || null : null,
      }
      this.handleLastedSelectBrandInfo();
      exportBrandSourceCell(params).then(res=>{
        that.disabledBtn = false
        if(res.code=='00000'){
          sessionStorage.removeItem('lastedSelectBrandInfo_'+document.domain);
          if(res.data){
            location.href = `https://tm-files.oss-cn-beijing.aliyuncs.com/${res.data}`
            that.$nextTick(()=>{
              // that.$store.commit(`common/${type.SET_CHECK_ALL_FLAG}`, false);
              // that.initHomeStoreState();
              that.showExportSettingDialogVisible = false;
            })
          }
        } else{
          that.$toast.showToast(res.msg);
        }
      })
    },
    // 取消收藏按钮点击
    async handleCancleCollect() {
      let tempSelectedCollectList = cloneDeep(this.selectBrandArr);
      if (this.selectBrandArr.length > 600) {
        this.$toast.showToast('最多选择600个商标');
        return;
      }
      if (tempSelectedCollectList.length) {
        this.handleLastedSelectBrandInfo();
        let cancleCollectArr = [];
        tempSelectedCollectList.forEach(el => {
          cancleCollectArr.push(el.id);
        });
        const {code} = await cancelCollection({
          userId: this.userInfo.userId,
          brandId: cancleCollectArr.join(',')
        });
        if (code == '00000') {
          sessionStorage.removeItem('lastedSelectBrandInfo_'+document.domain);
          this.initHomeStoreState();
          this.$store.commit(`myCollect/${type.CANCLE_BATCH_COLLECT_SUCCESS}`, true);
        } else {
          // this.$store.commit(`myCollect/${type.CANCLE_BATCH_COLLECT_SUCCESS}`, false);
        }
      }
    },
    initHomeStoreState() {
      this.$store.commit(`home/${type.SET_BATCH_SHARE}`, false);
      this.$store.commit(`home/${type.SET_SELECT_COUNT}`, 0);
      this.$store.commit(`home/${type.SET_ALL_SELECT}`, false);
      this.$store.commit(`home/${type.SET_SELECTED_BRAND_ARR}`, []);
      this.$store.commit(`common/${type.SET_CHECK_ALL_FLAG}`, false);
      sessionStorage.removeItem('lastedSelectBrandInfo_'+document.domain);
    },
    handleLastedSelectBrandInfo(){
      let lastedSelectBrandInfo = {};
      if(this.selectBrandArr && this.selectBrandArr.length > 0){
        lastedSelectBrandInfo = {
          SET_SELECTED_BRAND_ARR: this.selectBrandArr,
          lastedUserIdStr: this.userInfo.userId
        }
      }
      sessionStorage.setItem('lastedSelectBrandInfo_'+document.domain, JSON.stringify(lastedSelectBrandInfo));
    },
    // 加入推荐按钮点击
    async handleAddReconmmend() {
      if (this.selectBrandArr.length == 0) {
        this.$toast.showToast('请选择要推荐的商标');
        return;
      }
      if (this.selectBrandArr.length > 600) {
        this.$toast.showToast('最多选择600个商标');
        return;
      }
      let tempSelectedCollectList = cloneDeep(this.selectBrandArr);
      if (tempSelectedCollectList.length) {
        this.handleLastedSelectBrandInfo();
        let cancleCommandArr = [];
        tempSelectedCollectList.forEach(el => {
          cancleCommandArr.push(el.id);
        });
        const {code} = await addRecommend({
          userId: this.userInfo.userId,
          esIds: cancleCommandArr.join(',')
        });
        if (code == '00000') {
          sessionStorage.removeItem('lastedSelectBrandInfo_'+document.domain);
          this.$toast.showToast('加入推荐成功');
          this.initHomeStoreState();
          this.$store.commit(`myCollect/${type.CANCLE_BATCH_COLLECT_SUCCESS}`, true);
          // this.$store.commit(`broker/${type.SET_BROKER_BATCH}`, false);
        } else {
          this.$toast.showToast('加入推荐失败');
          // this.$store.commit(`myCollect/${type.CANCLE_BATCH_COLLECT_SUCCESS}`, false);
        }
      }
    },
    // 改价分享按钮点击
    handlePriceChange() {
      if (this.selectBrandArr.length == 0) {
        this.$toast.showToast('请选择要改价的商标');
        return;
      }
      if (this.selectBrandArr.length > 600) {
        this.$toast.showToast('最多选择600个商标');
        return;
      }
      this.sharePriceVisible = true;
      this.selectedBrandList = cloneDeep(this.selectBrandArr);
      this.handleLastedSelectBrandInfo();

    },
    // 关闭改价分享弹窗
    handleCloseShareDialog() {
      this.sharePriceVisible = false;
    },
    toNineGridPage(){
      if (this.selectBrandArr.length > 100) {
        this.$toast.showToast('亲~九宫格分享最多支持100件商标哟，请调整后再试试~',3000);
        return;
      }
      this.handleLastedSelectBrandInfo();
      if(this.isClient && window.navigator.userAgent.indexOf('Electron') < 0){
        this.$router.push({path: "/nineGridShare", query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}})
      } else {
        let nineGridPage = `${location.protocol}//${location.host}/nineGridShare?fromPath=${this.isClient==1 ? 'qdsClient' : 'PC'}`;
        window.open(nineGridPage);
      }
      
    },
  }
}
</script>

<style lang="scss" scoped>
  .select-all-footer {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    height: 80px;
    z-index: 999;
    border-top: 1px solid #e2e3e8;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    .footer-cont {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 1240px;
      height: 36px;
      margin: 0 auto;
      transform: translateY(22px);
      padding: 0 20px;
      box-sizing: border-box;
      .cont-check {
          color: #222;
          font-size: $font-size-medium;
      }
      .cont-payload {
        flex: 1;
      }
      .cont-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .cont-desc {
          padding-left: 20px;
          color: #999;
          background: url('../assets/images/footer/icon-warning.png') no-repeat 0 center;
          background-size: 12px;
          font-size: $font-size-medium;
          margin-right: 20px;
        }
        .cont-checked {
          color: #222;
          font-size: $font-size-medium;
          padding-bottom: 4px;
          margin-right: 20px;
          span {
            color: $color-text;
            font-size: $font-size-large-x;
          }
        }
        .btn-export {
          width: 140px;
          height: 36px;
          line-height: 36px;
          font-size: 15px;
          border-radius: 0;
          background: #fff;
          border: 1px solid #FF9400;
          color: #FF9400;
          cursor: pointer;
          margin-right: 20px;
        }
        .btn-nextStep {
          width: 140px;
          height: 36px;
          line-height: 36px;
          font-size: 15px;
          border-radius: 0;
          background: #FF9400;
          border: 1px solid #FF9400;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
  .showExportSettingDialog{
    .showExportSettingContent{
      .info{
        display: flex;
        font-size: 14px;
        line-height: 38px;
        .info-left{
          width: 80px;
        }
        .info-right{
          ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner{
            color: #FF7200;
            border-color: #FF7200;
            background-color: rgba(255, 114, 0, 0.1);
          }
          span{
            color: #FF9400;
            font-weight: bold;
            font-size: 16px;
          }
          .el-input{
            width: 120px;
            ::v-deep .el-input__inner{
              height: 32px;
              line-height: 32px;
            }
          }
          .el-radio-group{
            ::v-deep .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner{
              border-color: #E4E7ED;
            }
          }
        }
      }
      p{
        line-height: 20px;
        color: #999;
        font-size: 12px;
        padding-left: 80px;
      }
      .price-sub-desc {
        margin: 14px 0 14px 80px;
        padding-left: 16px;
        color: #999;
        font-size: $font-size-small;
        background: url("../assets/images/footer/icon-warning.png") no-repeat 0 center;
        background-size: 12px;
      }
      .footer{
        margin: 50px auto 0;
        text-align: center;
        .exportBtn{
          display: inline-block;
          width: 120px;
          text-align: center;
          background: #FF7200;
          color: #fff;
        }
      }
    }
  }
</style>
