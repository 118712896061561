import BaseRequest from "@/utils/http/index";

let request = new BaseRequest("");

let prefix = "/api/patent";
let bsPreFix = "/bsapi/";

// 获取字典的值
export const sysDict = (params) => {
  return request.post("/api/brace/dict/findByEntity", params);
};
// 图片上传
export const imgUpload = (params) => {
  return request.postMul("/bsapi/upload/upload", params);
};
// 取消收藏接口
export const cancelCollection = (params) => {
  return request.post(prefix + "/collection/cancelCollection", params);
};

// 首页专利列表
export const queryPatentList = (params) => {
  return request.post(prefix + "/patentSearch/queryPatentList", params);
};
// 首页专利列表
export const queryOnSalePatentList = (params) => {
  return request.post(prefix + "/patentSearch/queryOnSalePatentList", {
    ...params,
    appKey: "quandashi4106565062",
  });
};
// 经纪人主页客户信息统计(经纪人自己查看)
export const agentViewCount = (params) => {
  return request.post(prefix + "/agent/agentViewCount", params);
};

// 经纪人主页客户信息统计(客户查看经纪人) agent/userViewAgentCount
export const zlUserViewAgentCount = (params) => {
  return request.post(prefix + "/agent/userViewAgentCount", params);
};

// 保存搜索记录， 调用接口后自动保存最近记录
export const saveHistoricalSearchRecord = (params) => {
  return request.post(
    prefix + "/patentSearch/saveHistoricalSearchRecord",
    params
  );
};

// 查询搜索记录
export const getHistoricalSearchRecord = (params) => {
  return request.post(
    prefix + "/patentSearch/getHistoricalSearchRecord",
    params
  );
};

// 二维码操作
export const createQrcode = (params) => {
  return request.post("/api/brace/bsMa/wxaCode", params);
};

// 根据id同步用户数据
export const synchronizeUser = (params) => {
  return request.post(prefix + "/user/synchronizeUser", params);
};

// 导出excel
export const exportBrandSourceCell = (params) => {
  return request.post(prefix + "/patentSearch/exportPatentCell", params);
};

// 改价
export const addMarkupRecord = (params) => {
  return request.post(prefix + "/markupRecord/addMarkupRecord", params);
};

// 分享商标
export const addShare = (params) => {
  return request.post(prefix + "/share/addShare", params);
};

// 图片转为base64
export const oss2Base64 = (params) => {
  return request.post("/api/brace/tools/oss2Base64", params);
};
// 查询经纪人信息
export const getSourceAgent = (params) => {
  return request.post(prefix + "/agent/getSourceAgent", params);
};

// 专利个人主页-按天推荐统计
export const getPatentRecDayStatistics = (params) => {
  return request.post(prefix + "/recommendAgent/getRecDayStatistics", params);
};
// 专利个人主页-每天推荐专利明细
export const getPatentRecDayDetailDto = (params) => {
  return request.post(prefix + "/recommendAgent/getRecDayDetailDto", params);
};

// 埋点
export const clickRecord = (params) => {
  return request.post("/api/source/sourceadmin/clickRecord", params);
};

// 获取专利 封面信息
export const coverImage = (params) => {
  return request.postPdf("/bsapi/patent/coverImage/" + params.pn, params);
};

// 新增访客记录
export function zlAddTalk(params) {
  return request.post(prefix + "/talk/addTalk", params);
}
// 个人主页-添加、修改用户备注
export const updateUserRemark = (params) => {
  return request.post(prefix + "/visit/updateUserRemark", params);
};

// 个人主页-访客列表
export const getVisitList = (params) => {
  return request.post(prefix + "/visit/getVisitList", params);
};
// 个人主页-访客列表-根据用户类型统计用户数量
export const hvCountByUserType = (params) => {
  return request.post(prefix + "/patenth5/visit/countByUserType", params);
};

// 个人主页-分享访客列表
export const getShareUserList = (params) => {
  return request.post(prefix + "/userShare/getShareUserList", params);
};
// 个人主页-分享访客-根据用户类型统计用户数量
export const svCountByUserType = (params) => {
  return request.post(prefix + "/patenth5/userShare/countByUserType", params);
};

// 个人主页-绑定列表
export const queryBindList = (params) => {
  return request.post(prefix + "/user/queryBindList", params);
};
// 个人主页-分享访客-根据用户类型统计用户数量
export const vrCountByUserType = (params) => {
  return request.post(prefix + "/patenth5/binding/countByUserType", params);
};

// 记录主页访客
export const zlAgentPageVisitor = (params) => {
  return request.post(prefix + "/agent/agentPageVisitor", params);
};
// 清除经纪人主页访客信息-(主页访客，分享访客，绑客记录)
export const deleteUserNew = (params) => {
  return request.post(prefix + "/visit/deleteUserNew", params);
};


