import Vue from "vue";

function isEmptyObject(obj) {
  let i;
  for (i in obj) {
    return false;
  }
  return true;
}

export const registerFilters = (arr) => {
  console.log(arr);
  if (arr.length == 0) return;
  let i = 0;
  while (i < arr.length) {
    if (isEmptyObject(arr[i])) {
      continue;
    }
    Vue.filter(arr[i].filter, arr[i].filterFun);
    i++;
  }
};

// 分转元
export const fenToYuan = (val) => {
  if (val == "" || typeof Number(val) !== "number") return val;
  return String((Number(val) / 100));
}

// 分转元+fixed
export const fenToYuanFixed = (val) => {
  if (val == "" || typeof Number(val) !== "number") return val;
  return (Number(val) / 100).toFixed(2);
}

