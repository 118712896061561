<template>
  <div class="home-bottom" :class="$route.path == '/brandInfo'? 'bgImg':''">
    <div class="company-addr">
      <div class="addr-left">
        <div class="source-company">
          武汉梦知网知识产权服务有限公司
        </div>
        <div class="source-address">
          地址：湖北省武汉市武昌区武珞路456号新时代商务中心主楼23层6号
        </div>
        <div class="source-email">
          邮箱：biaoyuan@quandashi.com
        </div>
      </div>
      <div class="addr-right">
        <div class="right-mini">
          <img src="@/assets/images/home/ewm.png" />
        </div>
        <div class="right-phone">
          400-8000-211
        </div>
      </div>
    </div>
    <div class="copy-right">
      版权所有 © 2020 IPMASTER 京ICP备14062113号-1
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-bottom {
    width: 100%;
    background-color: #fff;

    .company-addr {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 1240px;
      height: 180px;
      margin: 40px auto 0;
      border-bottom: 1px solid #E4E4E4;

      .addr-left {
        flex: 1;
        text-align: left;

        .source-company {
          font-size: $font-size-medium;
          font-weight: bold;
          color: #222222;
          line-height: 16px;
        }

        .source-address {
          font-size: $font-size-medium;
          color: #222222;
          margin-top: 32px;
          margin-bottom: 8px;
          line-height: 16px;
        }

        .source-email {
          font-size: $font-size-medium;
          color: #222222;
        }
      }

      .addr-right {

        // align-self: flex-end;
        // justify-items: flex-end;
        .right-mini {
          img {
            width: 76px;
            height: 76px;
          }
        }

        .right-phone {
          margin-top: 10px;
          font-size: $font-size-medium-x;
          font-weight: bold;
          color: #222222;
          line-height: 16px;
        }
      }
    }

    .copy-right {
      width: 100%;
      font-size: $font-size-small;
      color: #bbb;
      line-height: 16px;
      text-align: center;
      padding: 35px 0;
    }
  }

  .bgImg {
    height: 254px;
    background: url('@/assets/images/menubar/pageBottom.png') no-repeat;
    background-size: 100% 100%;

    .company-addr {
      color: #3A3E55 !important;
      margin-top: 0;
    }

    .copy-right {
      padding: 28px 0;
      color: #9D9FAC;
    }

    .right-phone {
      color: #FFFFFF !important;
    }

    .addr-left {

      .source-company,
      .source-address,
      .source-email {
        color: #fff !important;
      }
    }
  }
</style>