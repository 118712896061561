<template>
  <div
    v-show="!noNav"
    class="g-breadcrumb"
  >
    <!-- <el-breadcrumb
      class="breadcrumb"
      :separator="separatorPayload"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">
        标源
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ secondaryName }}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="breadcrumb-title">
      <div
        class="title-start"
        :class="{'title-start-before': $route.path !== '/home'}"
        :style="$route.path == '/home' ? 'cursor: auto;' : ''"
        @click="goHomePage"
      >
        {{ $route.path == '/home' ? '标源' : '标源首页' }}
      </div>
      <div
        class="title-mid"
        :style="$route.path == '/home' ? 'color: #222; font-weight: bold;' : ''"
      >
        {{ $route.path == '/home' ? '-' : '>>' }}
      </div>
      <div
        class="title-end"
        :style="$route.path == '/home' ? 'color: #222; font-weight: bold; cursor: auto;' : ''"
      >
        {{ secondaryName }}
      </div>
    </div>
    <div
      v-if="maintainStatus==1"
      class="maintainWarning"
    >
      <span>温馨提示：</span>
      为提供更好产品体验，{{ maintainDate }} 平台维护例行维护，届时标源交易暂时无法使用，由此带来不便，敬请谅解！
    </div>
    <div
      class="breadcrumb-back"
      @click="routerFadeBack"
    >
      返回>>
    </div>
  </div>
</template>

<script>
/**
 * 面包屑组件
 */
import { deleteUserNew } from "@/apis/broker/patentBroker";
import { mapState } from 'vuex';
import base from '@/utils/js/base';
export default {
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     // 通过 `vm` 访问组件实例
  //     if (to.path !=="/" || to.path !== '/home') {
  //       vm.separatorPayload = '>>'
  //     }
  //   })
  // },
  props: {
    secondaryNameOther: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      separatorPayload: "-",
      secondaryName: '全网闲置转让商标查询',
      noNav: false,
      // directRouterBack: { // 定义特定路由返回的地址集合，不然默认都返回到Home
      //   '/orderDetail': '/tradeOrder'
      // },
      fromHomeMap: ['/brokerHome', '/contractAdd', '/tradeOrder', '/boutiqueBrand', '/transferBrand', '/myCollect', '/release', '/markup', '/contractList', '/transferList',
        '/lockBrands', '/couponList', '/messageList', '/helpCenter', '/couponCenter'
      ] // 定义返回Home特定路由, 不然默认都返回到上一级
    }
  },
  computed: {
    ...mapState('maintainInfo', {
      maintainStatus: state => state.maintainStatus,
      maintainDate: state => state.maintainDate
    }),
    ...mapState("userInfo", {
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      hasLoginInfo: state => state.hasLoginInfo,
      agentInfo: state => state.agentInfo,
      partnerType: state => state.partnerType,
      hasUserInfo: state => state.hasLoginInfo,
      isClient: state => state.isClient
    }),
  },
  watch: {
    $route(){
      this.secondaryName = (this.$route.meta && this.$route.meta.breadCrumbName) || '默认路由';
      this.noNav = (this.$route.meta && this.$route.meta.isNoNav) || false;
      if (this.$route.path == '/markup') {
        this.noNav = true;
      }
      setTimeout(() => {
        this.$route.meta.isNoNav = this.maintainStatus==0 ? true : false;
      }, 0);
    },
  },
  mounted(){
    if(this.$route.path == '/nineGridShare'){
      this.secondaryName = '制作九宫格';
    }
  },
  methods: {
    // 返回首页
    goHomePage() {
      if (this.$route.path == '/home') return;
      this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
    },
    // 返回
    routerFadeBack() {
      console.log(this.$route.query);
      if (this.fromHomeMap.includes(this.$route.path)) {
        this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
      } else if(this.$route.path == '/zlBrokerHome'){
        let href = null;
        if(this.$route.query && this.$route.query.fromPage && this.$route.query.fromPage=='zl'){
          let type = [1];
          if(sessionStorage.getItem('isVisitedZLShare')){
            type.push(2)
          }
          if(sessionStorage.getItem('isVisitedZLBind')){
            type.push(3)
          }
          deleteUserNew({agentId: this.userInfo && this.userInfo.userId, type: type.join(',')}).then(res=>{})
          if(this.$route.query.sourceFrom && this.$route.query.sourceFrom=='self' && this.isClient==1){
            if(location.host=='by.ipjy365.com'){
              href = `https://by.ipjy365.com/home?fromPath=` + this.isClient ? 'qdsClient' : 'PC';
              window.location.href = href;
            } else if(location.host=='apre-by.ipjy365.com') {
              href = `https://apre-by.ipjy365.com/home?fromPath=` + this.isClient ? 'qdsClient' : 'PC';
              window.location.href = href;
            } else {
              this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
            }
          } else {
            if(location.host=='by.ipjy365.com'){
              href = `https://zl.ipjy365.com/patentList?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
            } else {
              href = `https://apre-zl.ipjy365.com/patentList?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
            }
            window.location.href = href;
          }
        } else {
          if(location.host=='by.ipjy365.com'){
            href = `https://by.ipjy365.com/home?fromPath=` + this.isClient ? 'qdsClient' : 'PC';
            window.location.href = href;
          } else if(location.host=='apre-by.ipjy365.com') {
            href = `https://apre-by.ipjy365.com/home?fromPath=` + this.isClient ? 'qdsClient' : 'PC';
            window.location.href = href;
          } else {
            this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
          }
        }
      } else if(this.$route.path == '/comBrokerHome'){
        let href = null;
        if(this.$route.query && this.$route.query.fromPage && this.$route.query.fromPage=='zl'){
          if(location.host=='by.ipjy365.com'){
            href = `https://zl.ipjy365.com/patentList?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
          } else {
            href = `https://apre-zl.ipjy365.com/patentList?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
          }
          window.location.href = href;
        } else {
          if(location.host=='by.ipjy365.com'){
            href = `https://by.ipjy365.com/home?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
            window.location.href = href;
          } else if(location.host=='apre-by.ipjy365.com') {
            href = `https://apre-by.ipjy365.com/home?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient==1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
            window.location.href = href;
          } else {
            this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
          }
        }
      } else if (this.$route.path == '/orderDetail') {
        this.$router.push('/tradeOrder');
      } else {
        if (this.$route.path == '/brandInfo') {
          if (window.history.state == null) {
            this.$router.push({path: '/home', query :{fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
          } else {
            this.$router.go(-1);
          }
        } else {
          this.$router.go(-1);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .g-breadcrumb {
    width: 100%;
    min-width: 1280px;
    padding: 15px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    // ::v-deep .el-breadcrumb__inner {
    //   font-weight: bold;
    //   font-size: $font-size-medium;
    //   color: #000;
    // }
    // ::v-deep .el-breadcrumb__separator {
    //   font-weight: bold;
    //   font-size: $font-size-medium;
    //   color: #000;
    //   margin: 0 4px;
    // }
    .breadcrumb-title {
      position: relative;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      .title-start {
        cursor: pointer;
        color: #666;
      }
      .title-mid {
        margin: 0 5px;
      }
      .title-end {
        cursor: pointer;
        color: #666;
      }
    }
    .maintainWarning{
      margin-left: 15px;
      font-size: 12px;
      padding: 10px;
      background: #fff1e5;
      color: #666666;
      span{color: #ff7200;}
    }
    .breadcrumb-back {
      font-size: 14px;
      color: #999;
      text-decoration: underline;
      cursor: pointer;
    }
  }
</style>