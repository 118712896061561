const brandInfo = [
  {
      path: '/brandInfo',
      name: 'brandInfo',
      // component: () => import(/* webpackChunkName: "brandInfo" */ '@/views/brandInfo/indexBck'),
      component: () => import(/* webpackChunkName: "brandInfo" */ '@/views/brandInfo/index'),
      meta: {
        name: '交易商标__全网商标交易查询_标源',
        breadCrumbName: '标源详情'
      }
  }
];

export default brandInfo;
