import BaseRequest from "@/utils/http/index";

let request = new BaseRequest('');

let prefix = "/api/";

// 取消收藏接口
export const cancelCollection = (params) => {
  return request.post(prefix + 'source/collection/cancelCollection', params);
};

// 查询用户收藏列表
export const queryCollectBrandList = (params) => {
  return request.post(prefix + 'source/collection/queryCollectBrandList', params);
};

// 查询用户收藏商标大类统计
export const queryCollectBrandIntCls = (params) => {
  return request.post(prefix + 'source/collection/queryCollectBrandIntCls', params);
};

