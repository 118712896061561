const markup = [
    {
        path: '/markup',
        name: 'markup',
        component: () => import(/* webpackChunkName: "markup" */ '@/views/markup/markup'),
	    meta: {
            name: '标源_商标买卖_改价列表',
			breadCrumbName: '全网商标交易查询',
		    isNoNav: true
	    }
    },
]
export default markup;