import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './routes/home'
import brokerHome from './routes/broker'
import about from './routes/about'
import release from './routes/release'
import markup from './routes/markup'
import brandInfo from './routes/brandInfo';
import tradeOrder from './routes/tradeOrder';
import orderDetail from './routes/orderrDetail';
import myCollect from './routes/myCollect';
import boutique from './routes/boutique';
import contract from "@/router/routes/contract";
import transfer from "@/router/routes/transfer";
import lockBrands from "@/router/routes/lockBrands";
import coupon from "@/router/routes/coupon";
import websiteMaintenance from "@/router/routes/websiteMaintenance";
import userCenter from '@/router/routes/userCenter';
import nineGridShare from '@/router/routes/nineGridShare';
import myBrowseRecord from '@/router/routes/myBrowseRecord';
import recommendList from '@/router/routes/recommendList';

import { Message } from 'element-ui'
import base from '@/utils/js/base';
import store from '@/store';
import * as type from '@/store/mutation-types';
import { getAgentTypeById, getInnerMsgCount, queryMsgStatus, querySourceMaintain, synchronizeUser,getRoleMenu,queryConstantOptimizeInfo } from '@/apis/home/user';
import { getLoginInfo } from '@/apis/userCenter/userCenter';

Vue.use(VueRouter)

// 解决 NavigationDuplicated: Avoided redundant navigation to current location 报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  ...home,
  ...brokerHome,
  ...about,
  ...release,
  ...markup,
  ...contract,
  ...transfer,
  ...lockBrands,
  ...coupon,
  ...brandInfo,
  ...tradeOrder,
  ...orderDetail,
  ...myCollect,
  ...boutique,
  ...websiteMaintenance,
  ...userCenter,
  ...nineGridShare,
  ...myBrowseRecord,
  ...recommendList,
]

const router = new VueRouter({
  routes,
  mode: 'history'
});

const setTitle = (title='全网闲置转让商标查询') => {
  document.title = title
  document.head.querySelector('title').innerText = title
};

const COMMON_PARAMS = {
  "appKey":"quandashi4106565062",
  "session":"",
  "format":"json",
  "partnerId":"",
  "targetAppKey":"",
  "signMethod":"md5",
  "executor":"140fcf1dfb9b6368d33e1d16d14fcad3",
  "v":"1.0",
  "inputType":1,
};

// 获取头部未读信息
const getMsgCount = (id) => {
  getInnerMsgCount({
    ...COMMON_PARAMS,
    "timestamp": new Date().getTime() + 3,
    "sign":new Date().getTime() + 4,
    "userId": id
  }).then(res=>{
    if(res.code === 9091 && res.subCode === 10002){
      let totalUnReadCount = res.data.manaCount + res.data.qzdCount + res.data.sysCount;
      store.commit('userInfo/setUnredCount', totalUnReadCount);
    } else {
      store.commit('userInfo/setUnredCount', 0);
    }
  });
};
// 获取通知设置相关
const getNotifyMsgStatus = (id) => {
  var _this = this;
  queryMsgStatus({
    "userId": id
  }).then(res => {
    if(res.code === '00000'){
      let status = {
        checkedName: false,
        visitNotice: false,
        scoreNotice: false
      }
      res.data && res.data.length > 0 && res.data.forEach(item=>{
        if(item.msgType==1){//精准推荐
          status.checkedName = item.msgStatus==0 ? true : false;//0开启1关闭
        }
        if(item.msgType==2){//客户访问
          status.visitNotice = item.msgStatus==0 ? true : false;//0开启1关闭
        }
        if(item.msgType==3){//评分变动
          status.scoreNotice = item.msgStatus==0 ? true : false;//0开启1关闭
        }
      })
      store.commit('userInfo/setNotifyMsgStatus', status);
    } else {
      
    }
  })
}

// 去登录
const goLogin = () => {
  let info = {
    agentOrWriter: '',
    operatorId: "",
    operatorName: "",
    userId: "",
    userImg: "",
    userName: ""
  }
  let storeUserId = store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId;
  store.commit('userInfo/setHasLoginInfo', false);
  store.commit('userInfo/setLoginInfoOffice', info);
  store.commit('userInfo/setRolePermissionList', []);

  store.commit('userInfo/setAgentType', -1);
  store.commit('userInfo/setPartnerType', -1);
  store.commit('userInfo/setPayStatus', -1);
  store.commit('userInfo/setInteriorStatus', -1);
  store.commit('userInfo/setAccountType', -1);
  store.commit('userInfo/setBuiltinType', -1);

  localStorage.removeItem('tokenValue');
  localStorage.removeItem('tokenName');
  localStorage.removeItem('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  localStorage.removeItem('QDS_AGENT_ORGAN_INFO_'+document.domain);
  let clearUserIdentity = {
    agentType: 0,
    avatar: '',
    userName: '',
    userType: 0
  };
  store.commit(`common/${type.SET_USER_IDENTITY}`, clearUserIdentity);
  base.clearCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  base.clearCookie('QDS_AGENT_ORGAN_INFO_'+document.domain);
  base.clearCookie('QDS_COOKIE_'+document.domain);

  if(router.history.current.path!='/' && router.history.current.path!='/login'){
    sessionStorage.setItem('qdsHistoryUserId',storeUserId);
    sessionStorage.setItem('qdsHistoryRoutePath',router.history.current.path);
    sessionStorage.setItem('qdsHistoryRouteQuery',JSON.stringify(router.history.current.query));
  }
  router.push({path: '/login'});
};

function initLoginInfo() {
  let info = {
    agentOrWriter: '',
    operatorId: "",
    operatorName: "",
    userId: "",
    userImg: "",
    userName: ""
  }
  store.commit('userInfo/setHasLoginInfo', false);

  store.commit('userInfo/setAgentType', -1);
  store.commit('userInfo/setPartnerType', -1);
  store.commit('userInfo/setPayStatus', -1);
  store.commit('userInfo/setInteriorStatus', -1);
  store.commit('userInfo/setAccountType', -1);
  store.commit('userInfo/setBuiltinType', -1);

  store.commit('userInfo/setLoginInfoOffice', info);
  localStorage.removeItem('tokenValue');
  localStorage.removeItem('tokenName');
  localStorage.removeItem('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  localStorage.removeItem('QDS_AGENT_ORGAN_INFO_'+document.domain);
  let clearUserIdentity = {
    agentType: 0,
    avatar: '',
    userName: '',
    userType: 0
  };
  store.commit(`common/${type.SET_USER_IDENTITY}`, clearUserIdentity);
  store.commit('userInfo/setRolePermissionList', []);
  base.clearCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  base.clearCookie('QDS_AGENT_ORGAN_INFO_'+document.domain);
  base.clearCookie('QDS_COOKIE_'+document.domain);
}

async function getLoginInfoByToken(token,source) {
  let {code,subCode,data,subMessage} = await getLoginInfo({tokenValue: token});
  if(code==9091 && subCode==10002){
    base.localStorageSet("tokenValue", data.tokenValue);//将token存储起来
    base.localStorageSet("tokenName", data.tokenName);//将token存储起来
    let QDS_LOGIN_INFO_OFFICE = {
      operatorId: '',
      operatorName: '',
      userId: data.userId,
      userName: data.userName,
      userImg: '',
      agentOrWriter: '',
    }
    let QDS_AGENT_ORGAN_INFO = {
      agentIde: data.userId,
      account: data.userPhone,
      agentName: '',
      agentOrganId: '',
      agentOrganName: '',
    }
    base.setCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(QDS_LOGIN_INFO_OFFICE)));
    base.setCookie('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(QDS_AGENT_ORGAN_INFO)));
    localStorage.setItem('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(QDS_LOGIN_INFO_OFFICE)));
    localStorage.setItem('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(QDS_AGENT_ORGAN_INFO)));

    // 缓存角色
    getRoleMenu({userIdStr: data.userId,source: source, roleType: null}).then(req=>{
      if(req.code=='00000'){
        store.commit('userInfo/setRolePermissionList', req.data.menuList); // 角色功能
      } else {
        if((req.subCode == 10001 || req.subCode == 10004) && (req.subMessage.indexOf('token')>-1 || req.subMessage.indexOf('Token')>-1)){
          window.parent.postMessage("logout", "*");
        }
        initLoginInfo();
        router.push('/login');
        return;
      }
    })
    
  } else {
    if((subCode == 10001 || subCode == 10004) && (subMessage.indexOf('token')>-1 || subMessage.indexOf('Token')>-1)){
      window.parent.postMessage("logout", "*");
    }
    initLoginInfo();
    router.push('/login');
    return;
  }
}

/**
 * 这里的逻辑1. 头部信息那未读数据和合伙人接口每个页面都调用； 2. 除首页外其他页面需要进行登录校验
 */
router.beforeEach(async (to, from, next) => {
  if (to && to.meta && to.meta.name) {
    setTitle(to.meta.name);
  } else {
    setTitle();
  }
  
  store.commit(`home/${type.SHOW_UNLOGIN_DIALOG}`, false); // 清除未登录弹窗

  let passRoutes = ['/home','/login','/websiteMaintenance', '/agreementDetail']; // 定义不过校验的路由
  let moreRoutes = ['/release','/releasePublish','/markup','/contractList','/contractAdd','/contractPreview','/contractMaterial','/couponList','/tradeOrder','/orderDetail']; // 定义partnerType为1即审核通过的路由
  let brokerRoutes = ['/transferList','/transferAdd','/lockBrands']

  store.commit('userInfo/setCurrentRoute', JSON.stringify(to));
  
  if (to.query.qdsToken) {  
    // 将 qdsToken 存储到 localStorage  
    base.localStorageSet("tokenValue", to.query.qdsToken);
  
    const newPath = to.path; // 假设路径中不包含查询参数  
    // 如果路径中包含了其他查询参数，你可能需要保留它们  
    // 这里简单起见，我们假设只有 qdsToken 一个查询参数  
    const newQueryParams = Object.assign({}, to.query);  
    delete newQueryParams.qdsToken; // 移除 qdsToken  
  
    const newLocation = {  
      path: newPath,  
      query: newQueryParams  
    };  
    router.replace(newLocation).catch(err => {  
      console.error('Failed to replace route:', err);  
      next(false); // 如果替换失败，阻止导航  
    });  
  } else {  
    next();
  }
  if((to.path == '/home' || to.path == '/brandInfo' || to.path == '/zlBrokerHome' || to.path == '/comBrokerHome') && JSON.stringify(to.query)!='{}' && base.localStorageGet('tokenValue')){
    await getLoginInfoByToken(base.localStorageGet('tokenValue'), to.query.source);
  }

  let agentIde = base.getCookie('QDS_AGENT_ORGAN_INFO_'+document.domain) || localStorage.getItem('QDS_AGENT_ORGAN_INFO_'+document.domain);
  let decodeAgentInfo = decodeURIComponent(agentIde);
  let parseAgentInfo = (decodeAgentInfo != 'undefined' && decodeAgentInfo != '') ? JSON.parse(decodeAgentInfo) : {};

  let loginInfo = base.getCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain) || localStorage.getItem('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  let deCodeLoginInfo = decodeURIComponent(loginInfo);
  let parseDeCodeLoginInfo = loginInfo && (deCodeLoginInfo != 'undefined' && deCodeLoginInfo != '') && JSON.parse(deCodeLoginInfo) || {};
  let parseLoginInfo = (deCodeLoginInfo != 'undefined' && deCodeLoginInfo != '') ? JSON.parse(deCodeLoginInfo) : {};

  let storageClientUserType = '';
  let storageClientSource = '';
  let storageClientAgentOrganName = '';
  let isFromClient = window.location.href.indexOf('fromPath=qdsClient') > -1 || window.navigator.userAgent.indexOf('Electron') > -1 ? true: false;
  localStorage.setItem('storageIsFromClient',isFromClient);
  // let isFromClient = Array.from(document.getElementsByTagName('iframe')).filter(el => el.name == 'myIframe').length>0 ? true: false;
  let clientFromWay = '';
  if(to.path == '/home'){
    storageClientUserType = localStorage.getItem('storageClientUserType');
    storageClientSource = localStorage.getItem('storageClientSource');
    storageClientAgentOrganName = localStorage.getItem('storageClientAgentOrganName');
    if(JSON.stringify(to.query)!='{}' && to.query.userType){
      console.log('机构端路由带参-------------------');
      storageClientUserType = to.query.userType;
      storageClientSource = to.query.source;
      storageClientAgentOrganName = to.query.agentOrganName;
      clientFromWay = 'url';
    } else if(storageClientUserType){
      console.log('机构端缓存带参-------------------');
      clientFromWay = 'storage';
    } else {
      console.log('pc端--------------------------');
      store.commit('userInfo/setIsClient',false)
    }
  } else {
    storageClientUserType = localStorage.getItem('storageClientUserType');
    storageClientSource = localStorage.getItem('storageClientSource');
    storageClientAgentOrganName = localStorage.getItem('storageClientAgentOrganName');
    if(storageClientUserType){
      console.log('机构端缓存带参-------------------');
      clientFromWay = 'storage';
    } else {
      console.log('pc端--------------------------');
      store.commit('userInfo/setIsClient',false)
    }
  }

  if (to.path && passRoutes.includes(to.path)) {
    console.log(base.localStorageGet('tokenValue'));
    const tokenStr = base.localStorageGet('tokenValue')!==null || isFromClient;
    if (!tokenStr){
      let info = {
        agentOrWriter: '',
        operatorId: "",
        operatorName: "",
        userId: "",
        userImg: "",
        userName: ""
      }
      store.commit('userInfo/setHasLoginInfo', false);
      store.commit('userInfo/setRolePermissionList', []);

      store.commit('userInfo/setAgentType', -1);
      store.commit('userInfo/setPartnerType', -1);
      store.commit('userInfo/setPayStatus', -1);
      store.commit('userInfo/setInteriorStatus', -1);
      store.commit('userInfo/setAccountType', -1);
      store.commit('userInfo/setBuiltinType', -1);

      store.commit('userInfo/setLoginInfoOffice', info);
      next();
    } else if ((parseLoginInfo && parseLoginInfo.userId) || isFromClient) {
      console.log(store.state.userInfo);
      if(isFromClient && !parseLoginInfo){
        window.parent.postMessage("logout", "*");
        return;
      }
      // 存在存储用户登录信息
      store.commit('userInfo/setHasLoginInfo', true);
      store.commit('userInfo/setAgentInfo', parseAgentInfo);
      getMsgCount(parseLoginInfo.userId);
      getNotifyMsgStatus(parseLoginInfo.userId);
      synchronizeUser({
        userIdStr: parseLoginInfo.userId,
        roleType: null,
        source: storageClientSource ? storageClientSource : 1
      }).then(res => {
        if(res.code === '00000'){
          if(res.data && res.data.userName) {
            if(isFromClient){
              store.commit('userInfo/setIsClient',true);
              if(clientFromWay = 'url'){
                localStorage.setItem('storageClientUserType',res.data.userType);
                localStorage.setItem('storageClientSource',storageClientSource);
                localStorage.setItem('storageClientAgentOrganName',storageClientAgentOrganName);
              }
            } else {
              store.commit('userInfo/setIsClient',false)
            }
            // 缓存角色
            getRoleMenu({userIdStr: parseLoginInfo.userId,source: storageClientSource ? storageClientSource : 1, roleType: null,}).then(req1=>{
              if(req1.code=='00000'){
                store.commit('userInfo/setRolePermissionList', req1.data.menuList); // 角色功能
              } else {
                if((req1.subCode == 10001 || req1.subCode == 10004) && (req1.subMessage.indexOf('token')>-1 || req1.subMessage.indexOf('Token')>-1)){
                  window.parent.postMessage("logout", "*");
                }
                initLoginInfo();
                router.push('/login');
                return;
              }
            })
            // 判断是否灰度账号
            queryConstantOptimizeInfo({appKey: 'quandashi4106565062',signMethod: 'md5',inputType: 1,
            v: '1.0',executor: "140fcf1dfb9b6368d33e1d16d14fcad3",inputType: 1,timestamp: new Date().getTime(),sign: new Date().getTime(),
            format: 'json',type: 'grayscale_phone'}).then(optRes=>{
              if(optRes.code==9091 && optRes.subCode==10002){
                if(optRes.data && optRes.data.content && optRes.data.content.indexOf(parseAgentInfo.account) > -1){
                  base.setCookie('qds_version_id', 'v2');
                } else {
                  base.clearCookie('qds_version_id');
                }
              }
            })
            
            parseDeCodeLoginInfo.userImg = res.data && res.data.avatar ? res.data.avatar : '';
            parseDeCodeLoginInfo.userName = res.data && res.data.userName ? res.data.userName : '';
            localStorage.setItem('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(parseDeCodeLoginInfo)));
            base.setCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(parseDeCodeLoginInfo)));
            localStorage.setItem('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
            base.setCookie('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
            store.commit('userInfo/setLoginInfoOffice', parseDeCodeLoginInfo);

            store.commit('userInfo/setAgentType', res.data.agentType);
            store.commit('userInfo/setPartnerType', res.data.userType);
            store.commit('userInfo/setPayStatus', res.data.payStatus);
            store.commit('userInfo/setInteriorStatus', res.data.interiorStatus);
            store.commit('userInfo/setAccountType', res.data.accountType);
            store.commit('userInfo/setBuiltinType', res.data.builtinType);

            store.commit(`common/${type.SET_USER_IDENTITY}`, res.data);
            querySourceMaintain({}).then((result) => {
              if(result.code==='00000'){
                store.commit('maintainInfo/setMaintainStatus', result.data)
                store.commit('maintainInfo/setMaintainDate','12:00至20:00')
                if(result.data == 0){
                  getAgentTypeById({ agentId: parseLoginInfo.userId}).then(ress => {
                    if (ress.code !== '00000') {
                      Message.error(ress.msg);
                      next();
                      return;
                    }
                    if (ress.code === '00000') {
                      if (ress.data != 1) {
                        if(window.location.href.indexOf('websiteMaintenance') > -1){return}
                        return next('/websiteMaintenance');
                      } else {
                        next();
                      }
                    }
                  })
                } else {
                  return next();
                }
              } 
            }, error => {
              console.log(error);
              initLoginInfo();
              return next('/login')
            }).catch(error => {
              console.log(error);
              initLoginInfo();
              return next('/login')
            });
            next();
          } else {
            store.commit('userInfo/setAgentType', -1);
            store.commit('userInfo/setPartnerType', -1);
            store.commit('userInfo/setPayStatus', -1);
            store.commit('userInfo/setInteriorStatus', -1);
            store.commit('userInfo/setAccountType', -1);
            store.commit('userInfo/setBuiltinType', -1);
            
            store.commit('userInfo/setRolePermissionList', []);
            next();
          }
        } else {
          if((res.subCode == 10001 || res.subCode == 10004) && (res.subMessage.indexOf('token')>-1 || res.subMessage.indexOf('Token')>-1)){
            window.parent.postMessage("logout", "*");
            return;
          } else if(isFromClient){
            store.commit('userInfo/setIsClient',true);
            store.commit('userInfo/setHasLoginInfo', true);
            let arseDeCodeLogin = JSON.parse(JSON.stringify(parseLoginInfo));
            localStorage.setItem('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(arseDeCodeLogin)));
            base.setCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(arseDeCodeLogin)));
            localStorage.setItem('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
            base.setCookie('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
            if(clientFromWay = 'url'){
              localStorage.setItem('storageClientUserType',storageClientUserType);
              localStorage.setItem('storageClientSource',storageClientSource);
              localStorage.setItem('storageClientAgentOrganName',storageClientAgentOrganName);
            }
            store.commit('userInfo/setAgentType', 1);
            store.commit('userInfo/setPartnerType', localStorage.getItem('storageClientUserType')); // 经纪人类型
          }
          next();
        }
      }, err => {
        next();
      });
    } else {
      store.commit('userInfo/setHasLoginInfo', false);
      store.commit('userInfo/setRolePermissionList', []);

      store.commit('userInfo/setAgentType', -1);
      store.commit('userInfo/setPartnerType', -1);
      store.commit('userInfo/setPayStatus', -1);
      store.commit('userInfo/setInteriorStatus', -1);
      store.commit('userInfo/setAccountType', -1);
      store.commit('userInfo/setBuiltinType', -1);

      store.commit('maintainInfo/setMaintainStatus', 0)
      next();
    }
  } else {
    // let tokenStr = window.localStorage.getItem('tokenValue')!=null || isFromClient || window.localStorage.getItem('storageIsFromClient');
    let tokenStr = base.localStorageGet('tokenValue')!==null || isFromClient || JSON.parse(window.localStorage.getItem('storageIsFromClient'));
    let isLogin = store.state.userInfo.hasLoginInfo;
    if (!tokenStr){
      let info = {
        agentOrWriter: '',
        operatorId: "",
        operatorName: "",
        userId: "",
        userImg: "",
        userName: ""
      }
      let storeUserId = store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId;
      store.commit('userInfo/setHasLoginInfo', false);
      store.commit('userInfo/setRolePermissionList', []);
      
      store.commit('userInfo/setAgentType', -1);
      store.commit('userInfo/setPartnerType', -1);
      store.commit('userInfo/setPayStatus', -1);
      store.commit('userInfo/setInteriorStatus', -1);
      store.commit('userInfo/setAccountType', -1);
      store.commit('userInfo/setBuiltinType', -1);
      
      store.commit('userInfo/setLoginInfoOffice', info);
      if(store.state.userInfo.isClient){
        window.parent.postMessage('logout', '*');
        return
      } else {
        sessionStorage.setItem('qdsHistoryUserId',storeUserId);
        sessionStorage.setItem('qdsHistoryRoutePath',router.history.pending.path ? router.history.pending.path : router.history.current.path);
        sessionStorage.setItem('qdsHistoryRouteQuery',router.history.pending.query!='{}' ? JSON.stringify(router.history.pending.query) : JSON.stringify(router.history.current.query));
        return next('/login');
      }
    } else if (isLogin || parseLoginInfo) {
      if (parseLoginInfo) {
        store.commit('userInfo/setHasLoginInfo', true);
        store.commit('userInfo/setAgentInfo', parseAgentInfo);
        getMsgCount(parseLoginInfo.userId);
        getNotifyMsgStatus(parseLoginInfo.userId);
        synchronizeUser({
          userIdStr: parseLoginInfo.userId,
          roleType: null,
          source: storageClientSource ? storageClientSource : 1
        }).then(res => {
          if(res.code === '00000') {
            if(res.data && res.data.userName){
              if(isFromClient){
                store.commit('userInfo/setIsClient',true);
                localStorage.setItem('storageClientUserType',res.data.userType);
              } else {
                store.commit('userInfo/setIsClient',false)
              }
              // 缓存角色
              getRoleMenu({userIdStr: parseLoginInfo.userId,source: storageClientSource ? storageClientSource : 1, roleType: null,}).then(req1=>{
                if(req1.code=='00000'){
                  store.commit('userInfo/setRolePermissionList', req1.data.menuList); // 角色功能
                } else {
                  if((req1.subCode == 10001 || req1.subCode == 10004) && (req1.subMessage.indexOf('token')>-1 || req1.subMessage.indexOf('Token')>-1)){
                    window.parent.postMessage("logout", "*");
                  }
                  initLoginInfo();
                  router.push('/login');
                  return;
                }
              })
              // 判断是否灰度账号
              queryConstantOptimizeInfo({appKey: 'quandashi4106565062',signMethod: 'md5',inputType: 1,
              v: '1.0',executor: "140fcf1dfb9b6368d33e1d16d14fcad3",inputType: 1,timestamp: new Date().getTime(),sign: new Date().getTime(),
              format: 'json',type: 'grayscale_phone'}).then(optRes=>{
                if(optRes.code==9091 && optRes.subCode==10002){
                  if(optRes.data && optRes.data.content && optRes.data.content.indexOf(parseAgentInfo.account) > -1){
                    base.setCookie('qds_version_id', 'v2');
                  } else {
                    base.clearCookie('qds_version_id');
                  }
                }
              })

              parseDeCodeLoginInfo.userImg = res.data && res.data.avatar ? res.data.avatar : '';
              parseDeCodeLoginInfo.userName = res.data && res.data.userName ? res.data.userName : '';
              localStorage.setItem('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(parseDeCodeLoginInfo)));
              base.setCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain, encodeURIComponent(JSON.stringify(parseDeCodeLoginInfo)));
              localStorage.setItem('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
              base.setCookie('QDS_AGENT_ORGAN_INFO_'+document.domain, encodeURIComponent(JSON.stringify(parseAgentInfo)));
              store.commit('userInfo/setLoginInfoOffice', parseDeCodeLoginInfo);
              
              store.commit('userInfo/setAgentType', res.data.agentType);
              store.commit('userInfo/setPartnerType', res.data.userType);
              store.commit('userInfo/setPayStatus', res.data.payStatus);
              store.commit('userInfo/setInteriorStatus', res.data.interiorStatus);
              store.commit('userInfo/setAccountType', res.data.accountType);
              store.commit('userInfo/setBuiltinType', res.data.builtinType);
              
              store.commit(`common/${type.SET_USER_IDENTITY}`, res.data);
              querySourceMaintain({}).then((result) => {
                if(result.code==='00000'){
                  store.commit('maintainInfo/setMaintainStatus', result.data)
                  store.commit('maintainInfo/setMaintainDate','12:00至20:00')
                  if(result.data == 0){
                    getAgentTypeById({ agentId: parseLoginInfo.userId}).then(ress => {
                      if (ress.code !== '00000') {
                        Message.error(ress.msg);
                        next();
                        return;
                      }
                      if (ress.code === '00000') {
                        if (ress.data != 1) {
                          if(window.location.href.indexOf('websiteMaintenance') > -1){return}
                          return next('/websiteMaintenance');
                        } else {
                          next();
                        }
                      }
                    })
                  } else {
                    return next();
                  }
                } else {
                  initLoginInfo();
                  return next();
                }
              }, error => {
                console.log(error);
                initLoginInfo();
                return next('/login')
              }).catch(error => {
                console.log(error);
                initLoginInfo();
                return next('/login')
              });
              if (to.path && moreRoutes.includes(to.path)) {
                if(res.data.userType==0){
                  Message.warning('当前用户暂无权限')
                  router.push('/home')
                  next()
                } else {
                  next();
                }
              } else {
                next();
              }
              if(to.path && brokerRoutes.includes(to.path)){
                if(res.data.userType != 2){
                  Message.warning('当前用户暂无权限')
                  router.push('/home')
                  next()
                } else {
                  next();
                }
              } else {
                next();
              }
            } else {
              store.commit('userInfo/setRolePermissionList', []);
              
              store.commit('userInfo/setAgentType', -1);
              store.commit('userInfo/setPartnerType', -1);
              store.commit('userInfo/setPayStatus', -1);
              store.commit('userInfo/setInteriorStatus', -1);
              store.commit('userInfo/setAccountType', -1);
              store.commit('userInfo/setBuiltinType', -1);
              next();
            }
          } else {
            if((res.subCode == 10001 || res.subCode == 10004) && (res.subMessage.indexOf('token')>-1 || res.subMessage.indexOf('Token')>-1)){
              window.parent.postMessage("logout", "*");
              return;
            } else if(store.state.userInfo.isClient){
              return
            } else {
              next();
            }
          }
        }, err => {
          next();
        });
      } else {
        store.commit('userInfo/setHasLoginInfo', false);
        store.commit('userInfo/setRolePermissionList', []);
        
        store.commit('userInfo/setAgentType', -1);
        store.commit('userInfo/setPartnerType', -1);
        store.commit('userInfo/setPayStatus', -1);
        store.commit('userInfo/setInteriorStatus', -1);
        store.commit('userInfo/setAccountType', -1);
        store.commit('userInfo/setBuiltinType', -1);
        
        store.commit('maintainInfo/setMaintainStatus', 0)
        sessionStorage.setItem('qdsHistoryUserId', '');
        sessionStorage.setItem('qdsHistoryRoutePath',router.history.pending.path ? router.history.pending.path : router.history.current.path);
        sessionStorage.setItem('qdsHistoryRouteQuery',router.history.pending.query!='{}' ? JSON.stringify(router.history.pending.query) : JSON.stringify(router.history.current.query));
        router.push({path: '/login'});
      }
    } else {
      sessionStorage.setItem('qdsHistoryUserId', '');
      sessionStorage.setItem('qdsHistoryRoutePath',router.history.pending.path ? router.history.pending.path : router.history.current.path);
      sessionStorage.setItem('qdsHistoryRouteQuery',router.history.pending.query!='{}' ? JSON.stringify(router.history.pending.query) : JSON.stringify(router.history.current.query));
      goLogin();
    }
  }
});

export default router;
