<template>
  <div>
    <el-dialog
      v-loading="loadingFlag"
      v-dialogDrag
      title="分享配置"
      :visible.sync="sharePriceVisible"
      width="800px"
      :append-to-body="true"
      :close-on-click-modal="false"
      class="share-price"
      top="25vh"
      center
      @close="handleCloseDialog"
    >
      <div class="share-price-wrapper">
        <div class="price-body">
          <div class="price-body-tab flex-wrapper">
            <span
              class="flex-normal-left"
              style="align-self: flex-start"
            >调整价格：</span>
            <div class="flex-normal-right">
              <div class="change-price-desc">
                在市场价的基础上加价
              </div>
              <div class="change-price-tab">
                <el-tabs
                  v-model="activePriceName"
                  @tab-click="handlePriceTabClick"
                >
                  <el-tab-pane
                    label="百分比"
                    name="percent"
                  ></el-tab-pane>
                  <el-tab-pane
                    label="固定值"
                    name="fixPrice"
                  >
                  </el-tab-pane>
                </el-tabs>
                <el-input
                  v-model="modifyPricePercent"
                  class="modify-price"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @input="pricePercentChange"
                ></el-input>
                <span>{{ activePriceName=='percent' ? '%' : '元' }}</span>
              </div>
            </div>
          </div>
          <div class="price-body-desc flex-wrapper">
            <span class="default-share flex-normal-left">联系方式：</span>
            <div class="users-phone flex-normal-right">
              <el-input
                v-model="users"
                placeholder="请输入联系人"
                class="users"
                style="font-size: 12px;font-weight:normal;"
              ></el-input>
              <el-input
                v-model="phoneNo"
                placeholder="请输入联系电话"
                class="phone-no"
                style="font-size: 12px;font-weight:normal;"
              ></el-input>
            </div>
          </div>
          
          <div
            class="flex-wrapper"
            style="margin-top: 75px;"
          >
            <span class="flex-normal-right">
              <el-button
                class="normalBtn"
                type="primary"
                size="small"
                @click="handleCertain"
              >
                <span>生成分享链接</span>
              </el-button>
            </span>
          </div>
        </div>
        <div class="preview-body">
          <!-- 小程序分享 -->
          <div
            class="wxShareWrapper"
          >
            <div class="imgBox">
              <img
                :src="showShareUrl"
                alt=""
              />
            </div>
            <p @click="goShareOrigin">
              查看本次分享源
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addMarkupRecord, addShare, createQrcode, oss2Base64 } from "@/apis/home/home";
import * as type from "@/store/mutation-types";
import { clickRecord } from '@/apis/broker/patentBroker';

/**
 * 改价弹窗
 */
export default {
  components: {
    // singleUpload
  },
  props: {
    showDialog: {
      type: Boolean,
      default: true
    },
    // 单个
    singleBrand: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 多个
    multiBrand: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      prefix: "https://tm-files.oss-cn-beijing.aliyuncs.com/",
      defaultImg: 'this.src="' + require('@/assets/images/home/avatar.png') + '"',
      loadingFlag: false,
      sharePriceVisible: false,
      brandInfo: "",
      brandInfoCount: 0,
      activePriceName: "percent",
      modifyPricePercent: 0, // 加价减价金额
      users: "",
      phoneNo: "",
      priceRangeDesc: "", // 市场价
      marketPrice: "", // 改价后市场价
      multiPriceArr: [], // 多个商标价格区间的两个值
      showShareUrl: require("@/assets/images/home/noSharePic.png"),
    };
  },
  computed: {
    ...mapState("userInfo", {
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      agentInfo: state => state.agentInfo,
      isClient: state => state.isClient
    }),
    ...mapState("common", {
      baseQueryParams: state => state.oldInterfaceParams,
      brokerInfo: state => state.brokerInfo
    })
  },
  watch: {
    async showDialog(val) {
      this.sharePriceVisible = val;
      if (val) {
        this.loadingFlag = true;
        this.showShareUrl = require("@/assets/images/home/noSharePic.png");
        
        this.brokerInfo.userAvatarBase64 = await this.renderOss2Base(this.brokerInfo.userAvatar ? this.prefix + this.brokerInfo.userAvatar : '')
        if (this.brokerInfo && this.brokerInfo.name) {
          this.users = this.brokerInfo.name;
        }
        if (this.brokerInfo && this.brokerInfo.phone) {
          this.phoneNo = this.brokerInfo.phone;
        }
        this.computedBrandPrice();
        this.$nextTick(() => {
          this.handleCertain();
        });
      }
    },
    // 逻辑是这样的，先判断加价/减价百分比，然后判断是否单个商标，然后再计算价格区间
    modifyPricePercent: {
      handler(val){
        if (val !== "") {
          val = Number(val);
          if (this.activePriceName == "percent") {
            if (this.multiBrand.length > 1) {
              this.multiPriceArr.sort((a, b) => {
                return a - b;
              });
              this.marketPrice = `￥ ${this.fen2yuanFixed(
                this.multiPriceArr[0] * Number(1 + val / 100)
              )} - ${this.fen2yuanFixed(
                this.multiPriceArr[this.multiPriceArr.length - 1] * Number(1 + val / 100)
              )}`;
            } else {
              if (this.multiBrand.length == 1) {
                this.marketPrice = `￥ ${this.fen2yuanFixed(
                  this.multiBrand[0].price * Number(1 + val / 100)
                )}`;
              } else {
                this.marketPrice = `￥ ${this.fen2yuanFixed(
                  this.singleBrand.price * Number(1 + val / 100)
                )}`;
              }
            }
            this.multiBrand.forEach(item => {
              item.marketPrice = this.fen2yuanFixed(item.price * Number(1 + val / 100))
                ? `￥ ${this.fen2yuanFixed(item.price * Number(1 + val / 100))}`
                : "面议";
            });
          } if (this.activePriceName == "fixPrice") {
            if (this.multiBrand.length > 1) {
              this.multiPriceArr.sort((a, b) => {
                return a - b;
              });
              this.marketPrice = `￥ ${this.fen2yuanFixed(
                this.multiPriceArr[0] + Number( val * 100)
              )} - ￥ ${this.fen2yuanFixed(
                this.multiPriceArr[this.multiPriceArr.length - 1] + Number( val * 100)
              )}`;
            } else {
              if (this.multiBrand.length == 1) {
                this.marketPrice = `￥ ${this.fen2yuanFixed(
                  this.multiBrand[0].price + Number( val * 100)
                )}`;
              } else {
                this.marketPrice = `￥ ${this.fen2yuanFixed(
                  this.singleBrand.price + Number( val * 100)
                )}`;
              }
            }
            this.multiBrand.forEach(item => {
              item.marketPrice = this.fen2yuanFixed(item.price + Number( val * 100))
                ? `￥ ${this.fen2yuanFixed(item.price + Number( val * 100))}`
                : "面议";
            });
          }
        }
      },
      immediate: true,
    },
    
    multiBrand: {
      handler(arr) {
        arr &&
          arr.length > 0 &&
          arr.forEach(async item => {
            item.marketPrice = this.fen2yuanFixed(item.price)
              ? `￥ ${this.fen2yuanFixed(item.price)}`
              : "面议";
          });
        this.loadingFlag = false;
      },
      immediate: true,
      deep: true
    },
    
  },
  methods: {
    // 改价最大限制change
    pricePercentChange(val) {
      if (this.activePriceName == "percent" && Number(val) > 20) {
        this.modifyPricePercent = 20;
      } else if (this.activePriceName == "fixPrice" && Number(val) > 100000) {
        this.modifyPricePercent = 100000;
      }
    },
    // 计算价格和商标
    computedBrandPrice() {
      if (this.multiBrand.length > 1) {
        let brandArr = [];
        this.multiBrand.forEach(item => {
          
          this.multiPriceArr.push(item.price?item.price:0);
          if (item && item.tmName !== "") {
            brandArr.push(item.tmName);
          }
          this.brandInfoCount = brandArr.length;
          this.multiPriceArr.sort((a, b) => {
            return a - b;
          });

          if (this.multiPriceArr.length > 1) {
            if(this.fen2yuanFixed(this.multiPriceArr[0])==0 && this.fen2yuanFixed(this.multiPriceArr[this.multiPriceArr.length - 1])==0){
              this.priceRangeDesc = '￥ 面议';
            } else {
              this.priceRangeDesc = `￥ ${this.fen2yuanFixed(this.multiPriceArr[0])==0?'面议':this.fen2yuanFixed(this.multiPriceArr[0])} - ￥ ${this.fen2yuanFixed(this.multiPriceArr[this.multiPriceArr.length - 1])}`;
            }
          } else {
            if(this.fen2yuanFixed(this.multiPriceArr[this.multiPriceArr.length - 1])==0){
              this.priceRangeDesc = `￥面议`;
            } else {
              this.priceRangeDesc = `￥面议 - ${this.fen2yuanFixed(this.multiPriceArr[this.multiPriceArr.length - 1])==0?'面议':this.fen2yuanFixed(this.multiPriceArr[this.multiPriceArr.length - 1])}`;
            }
          }

          this.marketPrice = this.priceRangeDesc;
        });
        this.brandInfo = brandArr.splice(0, 3).join("、");
      } else {
        if (this.multiBrand.length == 1) {
          this.brandInfo = this.multiBrand[0].tmName;
          this.priceRangeDesc = `￥ ${this.fen2yuanFixed(this.multiBrand[0].price)==0?'面议':this.fen2yuanFixed(this.multiBrand[0].price)}`;
        } else {
          this.brandInfo = this.singleBrand.tmName;
          this.priceRangeDesc = `￥ ${this.fen2yuanFixed(this.singleBrand.price)==0?'面议':this.fen2yuanFixed(this.singleBrand.price)}`;
        }
        this.marketPrice = this.priceRangeDesc;
      }
    },
    fen2yuanFixed(val) {
      if (val) {
        if (val == "" || typeof Number(val) !== "number") return val;
        return (Number(val) / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    // 关闭dialog
    handleCloseDialog() {
      this.$emit("closeShare");
      this.resetShareDialog();
    },
    // 确认按钮点击
    async handleCertain() {
      if (this.verifyDialog()) {
        // 埋点
        let ipStaParam = {
          appKey: 'quandashi4106565062',
          signMethod: 'md5',
          inputType: 1,
          v: '1.0',
          executor: "140fcf1dfb9b6368d33e1d16d14fcad3",
          inputType: 1,
          format: 'json',
          timestamp: new Date().getTime(),
          sign: new Date().getTime(),

          userId: this.userInfo.userId,
          userIdStr: this.userInfo.userId || '0',
          projectCode: '144115',
          projectName: '小程序分享',
          source: 4,
          requestUlr: `${this.$route.path}`
        }
        clickRecord(ipStaParam).then(req=>{
          console.log(req);
        })

        const { code, data, subCode,msg } = await addMarkupRecord({
          ...this.createPriceParams(),
          userId: this.userInfo.userId
        });
        if (code == "00000") {
          sessionStorage.removeItem('lastedSelectBrandInfo_'+document.domain);
          this.queryAddShare();
        } else {
          this.$toast.showToast(msg)
        }
      }
    },
    // 校验
    verifyDialog() {
      if (this.users == "") {
        this.$message.warning("请输入联系人");
        return false;
      }
      if (this.phoneNo == "") {
        this.$message.warning("请输入联系电话");
        return false;
      }
      let reg = /^1\d{10}$/;
      if (!reg.test(this.phoneNo)) {
        this.$message.warning("请输入正确的联系电话");
        return false;
      }
      return true;
    },
    // 创建改价入参
    createPriceParams() {
      let queryParams = {
        contactName: this.users,
        contactPhone: this.phoneNo,
        markupType: this.activePriceName=='percent' ? 2 : 1,
        list: []
      };
      queryParams["markupType"] = this.activePriceName=='percent' ? 2 : 1;
      queryParams["markupScale"] = this.activePriceName=='percent' ? this.modifyPricePercent : null;
      queryParams["markupPrice"] = this.activePriceName=='fixPrice' ? this.modifyPricePercent * 100 : null;
      if (this.multiBrand.length) {
        this.multiBrand.forEach(item => {
          queryParams["list"].push({
            channelPrice: item.price,
            esId: item.id
          });
        });
      } else {
        queryParams["list"].push({
          channelPrice: this.singleBrand.price,
          esId: this.singleBrand.id
        });
      }

      return queryParams;
    },
    // 查询分享接口获取id
    async queryAddShare() {
      let queryArr = [];
      let params = {
        contactName: this.users,
        contactPhone: this.phoneNo,
        userId: this.userInfo.userId
      };
      // 参数处理比较复杂直接分享直接拿值  改价分享1.加价需要计算加价后的钱， 减价最低减到20%然后计算减价后的钱，多选的话每个商标都得计算
      if (this.multiBrand.length == 0) {
        if (this.activePriceName == "percent" && !!this.modifyPricePercent) {
          params = Object.assign({}, params, {
            esId: this.singleBrand.id,
            markupAfterPrice: parseInt(
              this.singleBrand.price * Number(1 + Number(this.modifyPricePercent) / 100)
            )
          });
          queryArr.push(params);
        } else {
          params = Object.assign({}, params, {
            esId: this.singleBrand.id,
            markupAfterPrice: parseInt(
              Number(this.singleBrand.price) + Number( this.modifyPricePercent * 100)
            )
          });
          queryArr.push(params);
        }
      } else if (this.multiBrand.length == 1) {
        if (this.activePriceName == "percent" && !!this.modifyPricePercent) {
          params = Object.assign({}, params, {
            esId: this.multiBrand[0].id,
            markupAfterPrice: parseInt(
              this.multiBrand[0].price * Number(1 + Number(this.modifyPricePercent) / 100)
            )
          });
          queryArr.push(params);
        } else {
          params = Object.assign({}, params, {
            esId: this.multiBrand[0].id,
            markupAfterPrice: parseInt(
              Number(this.multiBrand[0].price) + Number( this.modifyPricePercent * 100)
            )
          });
          queryArr.push(params);
        }
      } else {
        this.multiBrand.forEach(item => {
          if (this.activePriceName == "percent" && !!this.modifyPricePercent) {
            queryArr.push({
              ...params,
              esId: item.id,
              markupAfterPrice: parseInt(
                item.price * Number(1 + Number(this.modifyPricePercent) / 100)
              )
            });
          } else {
            queryArr.push({
              ...params,
              esId: item.id,
              markupAfterPrice: parseInt(
                Number(item.price) + Number( this.modifyPricePercent * 100)
              )
            });
          }
        });
      }
      const { code, data, subCode } = await addShare({
        list: queryArr
      });
      if (code == "00000") {
        createQrcode({
          width: 150,
          path: `pages/shareList/shareList?id=${data}`,
          type: "BRAND_SOURCE"
        }).then(
          res => {
            let base64PreFix = "data:image/jpeg;base64,";
            if (res.code == "00000") {
              this.showShareUrl = base64PreFix + res.data.data;
              this.$toast.showToast('二维码已刷新')
            } else {
              this.$toast.showToast("生成失败");
            }
          },
          err => {
            console.log(err);
          }
        );
      }
    },
    // 恢复弹窗初始值
    resetShareDialog() {
      this.multiPriceArr = [];
      this.brandInfo = "";
      this.priceRangeDesc = "";
      this.marketPrice = "";
      this.modifyPricePercent = 0;
      this.activePriceName = "percent";
    },
    // 关闭分享弹窗
    closeShareDialog() {
      this.resetShareDialog();
      this.handleCloseDialog();
      this.initHomeStoreState();
    },
    // 加价降价tab点击
    handlePriceTabClick(tab, event) {
      this.modifyPricePercent = 0;
      this.marketPrice = this.priceRangeDesc;
    },
    initHomeStoreState() {
      this.$store.commit(`home/${type.SET_BATCH_SHARE}`, false);
      this.$store.commit(`home/${type.SET_SELECT_COUNT}`, 0);
      this.$store.commit(`home/${type.SET_ALL_SELECT}`, false);
      this.$store.commit(`home/${type.SET_SELECTED_BRAND_ARR}`, []);
      this.$store.commit(`home/${type.CLEAR_SINGLE_BRAND}`, false);
    },
    goShareOrigin() {
      this.closeShareDialog();
      this.$router.push({path: "/markup", query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
    },
    async renderOss2Base(url) {
      try {
        if (!url) {
          return "";
        }
        let base64PreFix = "data:image/jpeg;base64,";
        let { code, data } = await oss2Base64({ ossUrl: url });
        if (code == "00000") {
          if (data) {
            return base64PreFix + data;
          } else {
            return "";
          }
        } else {
          return "";
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.share-price {
  ::v-deep .el-dialog__body {
    padding: 10px 25px 25px !important;
  }
  ::v-deep .el-dialog__header{
    padding: 15px 20px !important;
  }
}
.normalBtn {
  background-color: #ff7200;
  border-color: #ff7200;
  padding: 8px 15px;
  &:focus,
  &:hover {
    background: #ff7200;
    border-color: #ff7200;
  }
}
.share-price-wrapper {
  display: flex;
}
.price-body {
  color: #222;
  width: 400px;
}
.flex-wrapper {
  display: flex;
  margin-top: 15px;
  &:first-child {
    margin-top: 0;
  }
  .flex-normal-left {
    width: 80px;
    color: #666666;
    font-size: 12px;
    align-self: center;
  }
  .flex-normal-right {
    flex: 1;
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: rgba(255, 114, 0, 0.1);
      color: #ff7200;
      border-color: #ff7200;
    }
    ::v-deep .el-radio-button__inner {
      width: 110px;
    }
    .shareNameInput {
      width: 200px;
      margin-right: 5px;
    }
    .styleInput {
      width: 32px;
      margin-right: 5px;
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
      }
    }
    .styleWeight {
      display: inline-block;
      width: 32px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: 1px solid #dcdfe6;
    }
    .actBtn {
      padding: 8px 6px;
      background-color: rgba(255, 114, 0, 0.1);
      color: #ff7200;
      border-color: #ff7200;
    }
    .norBtn {
      padding: 9px;
      &:hover,
      &:focus {
        color: #606266;
        border-color: #dcdfe6;
        background-color: #fff;
      }
    }
  }
  .headerBgImg {
    display: flex;
    flex-wrap: wrap;
    .imgTip {
      color: #999999;
      font-size: 12px;
      margin-top: 8px;
      align-self: center;
      img {
        width: 12px;
      }
    }
  }
}
.price-body-title {
  display: flex;
  font-size: 12px;
  line-height: 15px;
}
.price-body-desc {
  color: #222;
  font-size: $font-size-small;
}
.price-body-tab {
  margin-top: 12px;
  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }
}
.users-phone {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.change-price-desc {
  color: #222;
  font-size: $font-size-small;
  margin-bottom: 10px;
  span {
    font-weight: bold;
  }
}
.change-price-tab {
  display: flex;
  flex-direction: row;
  line-height: 30px;
  ::v-deep .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__item.is-top,::v-deep .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__item.is-top.is-active {
    width: 55px;
  }
}
.change-price-orange {
  font-size: 20px;
  font-weight: bold;
  color: $color-text;
}
.price-sub-desc {
  margin-top: 14px;
  margin-bottom: 14px;
  padding-left: 16px;
  color: #999;
  font-size: $font-size-small;
  background: url("../assets/images/footer/icon-warning.png") no-repeat 0 center;
  background-size: 12px;
}
.inputGroup {
  width: 110px;
  margin-right: 10px;
  ::v-deep .el-input-group__append {
    padding: 0 10px;
  }
}

.preview-body {
  padding-left: 30px;
  .wxShareWrapper {
    width: 300px;
    margin: 0 0 ;
    text-align: center;
    .imgBox{
      width: 100%;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 180px;
        min-height: 130px;
        // height: 180px;
      }
    }
    
    p {
      color: #222222;
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
      line-height: 17px;
      margin-top: 10px;
    }
  }
  .picShareWrapper {
    max-width: 685px;
    border: 1px solid #d7d7d7;
    position: relative;
    margin-bottom: 80px;
    .picShareBox {
      height: 700px;
      max-width: 670px;
      padding: 10px 7px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fefefe;
      }
    }
    .nineGirdShare {
      // height: 100%;
      // width: 100%;
      // padding: 10px;
      // overflow: auto;
      width: auto;
      .share-header-wrapper {
        position: relative;
        .header-img {
          width: 100%;
          min-height: 50px;
          img {
            width: 100%;
          }
        }
        .share-header {
          width: 100%;
          text-align: center;
          font-family: MicrosoftYaHei;
          // line-height: 50px;
          position: absolute;
          .share-name{
            padding: 5px 20px;
            background: #fff;
            border-radius: 6px;
          }
        }
      }

      .share-content {
        // height: calc(100% - 125px);
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 2px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: #fefefe;
        }
        .singlePage {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          padding-left: 20px;
          padding-right: 10px;
          padding-top: 10px;
        }
        .brand-wrapper {
          display: flex;
          width: 150px;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          background-color: #fff;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 10px;
          box-sizing: border-box;
          .logo {
            width: 120px;
            height: 120px;
            line-height: 120px;
            img {
              width: 100%;
              line-height: 120px;
              max-height: 120px;
              vertical-align: middle;
            }
          }
          .tmName {
            color: #222222;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-align: center;
          }
          .marketPrice {
            color: #ff7200;
          }
          .tmName,
          .marketPrice,
          .intCls,
          .regNo {
            line-height: 15px;
          }
        }
      }

      .share-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 30px;
        .footer-left {
          display: flex;
          align-items: center;
          .img-avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .user-info {
            margin-left: 10px;
            p {
              color: #222;
              &:first-child {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 10px;
              }
              &:last-child {
                font-size: 14px;
              }
            }
          }
        }
        .footer-right {
          display: flex;
          align-items: center;
          .tip {
            color: #666666;
            line-height: 20px;
            margin-right: 10px;
          }
          .qrcode {
            width: 100px;
            height: 100px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .mutilPage {
    // display: flex;
    // flex-wrap: wrap;
    height: 700px;
    max-width: 680px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fefefe;
    }
    .mutil-wrapper {
      // transform: scale(0.2);
      .nineGirdShare {
        height: auto;
      }
      .idx {
        color: #222;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
  .opt-footer {
    position: absolute;
    bottom: -80px;
    min-width: 590px;
    .opt-line {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 12px;
      }
    }
  }
}
</style>