import BaseRequest from "@/utils/http/index";

let request = new BaseRequest('');

let prefix = "/api/";
let bsPreFix = '/bsapi/';

// 首页标源列表
export const queryFirstPageList = (params) => {
  return request.post(prefix + 'source/sourceh5/queryFirstPageList', {...params});
};
// 首页标源列表
export const getBrandSourceList = (params) => {
  return request.post(prefix + 'source/brandSourceList', {...params,appKey:'quandashi4106565062'});
};

// 搜索条件热门行业
export const popularIndustry = (params) => {
  return request.post(prefix + 'source/popularIndustry/findByEntity', params);
};

// 收藏单个商标
export const addCollect = (params) => {
  return request.post(prefix + 'source/collection/collectionBrand', params);
};

// 取消收藏
export const deleteCollect = (params) => {
  return request.post(prefix + 'source/collection/cancelCollection', params);
};

// 加入推荐
export const addRecommend = (params) => {
  return request.post(prefix + 'source/recommend/addRecommend', params);
};

// 取消推荐
export const deleteRecommend = (params) => {
  return request.post(prefix + 'source/recommend/deleteRecommend', params);
};

// 改价
export const addMarkupRecord = (params) => {
  return request.post(prefix + 'source/markupRecord/addMarkupRecord', params);
};

// 分享商标
export const addShare = (params) => {
  return request.post(prefix + 'source/share/addShare', params);
};

// 保存搜索记录， 调用接口后自动保存最近记录
export const saveHistoricalSearchRecord = (params) => {
  return request.post(prefix + 'source/saveHistoricalSearchRecord', params);
};

// 查询搜索记录
export const getHistoricalSearchRecord = (params) => {
  return request.post(prefix + 'source/getHistoricalSearchRecord', params);
};

// 二维码操作
export const createQrcode = (params) => {
  return request.post(prefix + 'brace/bsMa/wxaCode', params);
};

// 查看经纪人信息 source/getSourceAgent brokerInfo['status'] == 2 0-未认证，1-认证中，2-已认证，3-认证失败
export const getSourceAgent = (params) => {
  return request.post(bsPreFix + 'source/getSourceAgent', params);
};

// 我要求购
export const addBuying = (params) => {
  return request.post(prefix + 'source/buying/addBuying', params);
};

// 非合伙人查看收藏商标数接口 
export const queryCollectBrandNum = (params) => {
  return request.post(prefix + 'source/collection/queryCollectBrandNum', params);
};

// 免责声明弹窗
export const getRemindInfo = (params) => {
  return request.post(prefix + 'source/sourceh5/remind/getRemindInfo', params);
};

// 根据id同步用户数据
export const synchronizeUser = (params) => {
  return request.post(prefix + 'source/user/synchronizeUser', params);
};
// 根据手机号同步用户数据
export const synchronizeUserByPhone = (params) => {
  return request.post(prefix + 'source/user/synchronizeUserByPhone', params);
};
// 根据关键字查找聚合代理机构
export const getHomeAgentNum = (params) => {
  return request.post(prefix + 'source/getHomeAgentNum', params);
};

// 图片转为base64
export const oss2Base64 = (params) => {
  return request.post(prefix + 'brace/tools/oss2Base64', params);
};
// 图片转为base64--纯图
export const getColorBase64ImageByNetUrl = (params) => {
  return request.post(prefix + 'source/getColorBase64ImageByNetUrl', params);
};
// 图片转为base64--集合
export const getColorImageListByNetUrl = (params) => {
  return request.post(prefix + 'brace/tools/getImageColorListByNetUrl', params);
};

// 获取新到访客数量
export const agentVisitUser = (params) => {
  return request.post(prefix + 'source/agent/agentVisitUser', params);
};
// 清除新到访客数量
export const deleteAgentVisit = (params) => {
  return request.post(prefix + 'source/agent/deleteAgentVisit', params);
};
// 导出excel
export const exportBrandSourceCell = (params) => {
  return request.post(prefix + 'source/exportBrandSourceCell', params);
};

// 图形检索_返回所有数据
export const getRegNosByBase64 = (params) => {
  return request.post(bsPreFix + 'brand/图形检索_返回所有数据', params);
};