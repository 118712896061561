<template>
  <div
    v-if="hasUserInfo && !isClient"
    class="home-more"
  >
    <span class="more-img"></span>
    <el-dropdown 
      trigger="hover"
      class="more-drop"
      :hide-on-click="hideOnClick"
      @visible-change="dropdownChange"
    >
      <div class="el-dropdown-link">
        更多
        <span 
          :class="{'arrow-down': !dropVisiable, 'arrow-up': dropVisiable}" 
        ></span>
      </div>
      <el-dropdown-menu
        slot="dropdown"
        ref="dropDown"
        class="home-more-drop"
      >
        <el-dropdown-item 
          v-for="item in moreList"
          :key="item.jumpUrl"
          @click.native="handleItem(item)"
        >
          <span v-show="item.jumpUrl">{{ item.name }}</span>
          <el-popover
            v-show="!item.jumpUrl"
            placement="left-end"
            width="200"
            trigger="hover"
            popper-class="popWrapper"
            :offset="50"
          >
            <span slot="reference">{{ item.name }}</span>
            <div class="popContent">
              <img
                src="../../../assets/images/home/ewm.png"
                alt=""
              />
              <p>扫一扫，随时随地查询闲置商标</p>
            </div>
          </el-popover>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import base from '@/utils/js/base';
import { mapState } from 'vuex';
import * as type from '@/store/mutation-types';

// 首页更多
export default {
  data() {
    return {
      moreList: [],
      dropVisiable: false,
      hideOnClick: false,
    }
  },
  computed: {
    ...mapState('userInfo', {
      hasUserInfo: state => state.hasLoginInfo,
      unreadCount: state => state.unreadCount,
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      partnerType: state => state.partnerType,
      rolePermissionList: state => state.rolePermissionList,
      isClient: state => state.isClient,
    }),
    ...mapState('maintainInfo', {
      maintainStatus: state => state.maintainStatus,
      maintainDate: state => state.maintainDate
    }),
    ...mapState('common', {
      userIdentity: state => state.userIdentity
    }),
  },
  watch: {
    maintainStatus: {
      handler(val) {
        if (val == '0') {
          this.moreList = [];
        }
      },
      immediate: true
    },
    partnerType: {
      handler(val) {
        if (val <= 0) {
          this.moreList = [];
        } else {
          this.moreList = [];
          if (this.$route.query.fromPage == 'zl') {
            for (let i = 0; i < this.rolePermissionList.length; i++) {
              if (this.rolePermissionList[i].code == 'patent_menu_publish_manage') {
                this.moreList.push({
                  name: '发布管理',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/patentManage'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'patent_menu_collect_manage') {
                this.moreList.push({
                  name: '我的收藏',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/patentCollect'
                })
                continue;
              }
            }
          } else {
            for (let i = 0; i < this.rolePermissionList.length; i++) {
              if (this.rolePermissionList[i].code == 'first_page_hhr_home') {
                this.moreList.push({
                  name: '我的主页',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/brokerHome?fromPage=by'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_publish_manage') {
                this.moreList.push({
                  name: '发布管理',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/release'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'first_page_order_manage') {
                this.moreList.push({
                  name: '交易订单',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/tradeOrder'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_share_manage') {
                this.moreList.push({
                  name: '分享管理',
                  iconImg: require('@/assets/images/home/icon-fxgl.png'),
                  jumpUrl: '/markup'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_contract_manage') {
                this.moreList.push({
                  name: '转让合同',
                  iconImg: require('@/assets/images/home/icon-zrht.png'),
                  jumpUrl: '/contractList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_transfer_mark') {
                this.moreList.push({
                  name: '转让标记',
                  iconImg: require('@/assets/images/home/icon-zrbj.png'),
                  jumpUrl: '/transferList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_lock_manage') {
                this.moreList.push({
                  name: '锁标管理',
                  iconImg: require('@/assets/images/home/icon-sbgl.png'),
                  jumpUrl: '/lockBrands'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_coupon_manage') {
                this.moreList.push({
                  name: '优惠券管理',
                  iconImg: require('@/assets/images/home/icon-yhqgl.png'),
                  jumpUrl: '/couponList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_collect_manage') {
                this.moreList.push({
                  name: '我的收藏',
                  iconImg: require('@/assets/images/home/icon-wdsc.png'),
                  jumpUrl: '/myCollect'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_browsing_history') {
                this.moreList.push({
                  name: '浏览记录',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/myBrowseRecord'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_notice') {
                this.moreList.push({
                  name: '通知设置',
                  iconImg: require('@/assets/images/home/icon-tzsz.png'),
                  jumpUrl: 'notice/setting'
                })
                continue;
              }
              // TODO
              if (this.rolePermissionList[i].code == 'menu_coupon') {
                this.moreList.push({
                  name: '优惠券中心',
                  iconImg: require('@/assets/images/home/icon-tzsz.png'),
                  jumpUrl: '/couponCenter'
                })
                continue;
              }
            }

          }

        }
      },
      deep: true,
      immediate: true
    },
    '$route.params.fromPage': {
      handler(val) {
        if (val <= 0) {
          this.moreList = [];
        } else {
          this.moreList = [];
          if (this.$route.query.fromPage == 'zl') {
            for (let i = 0; i < this.rolePermissionList.length; i++) {
              if (this.rolePermissionList[i].code == 'patent_menu_publish_manage') {
                this.moreList.push({
                  name: '发布管理',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/patentManage'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'patent_menu_collect_manage') {
                this.moreList.push({
                  name: '我的收藏',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/patentCollect'
                })
                continue;
              }
            }
          } else {
            for (let i = 0; i < this.rolePermissionList.length; i++) {
              if (this.rolePermissionList[i].code == 'first_page_hhr_home') {
                this.moreList.push({
                  name: '我的主页',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/brokerHome?fromPage=by'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_publish_manage') {
                this.moreList.push({
                  name: '发布管理',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/release'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'first_page_order_manage') {
                this.moreList.push({
                  name: '交易订单',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/tradeOrder'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_share_manage') {
                this.moreList.push({
                  name: '分享管理',
                  iconImg: require('@/assets/images/home/icon-fxgl.png'),
                  jumpUrl: '/markup'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_contract_manage') {
                this.moreList.push({
                  name: '转让合同',
                  iconImg: require('@/assets/images/home/icon-zrht.png'),
                  jumpUrl: '/contractList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_transfer_mark') {
                this.moreList.push({
                  name: '转让标记',
                  iconImg: require('@/assets/images/home/icon-zrbj.png'),
                  jumpUrl: '/transferList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_lock_manage') {
                this.moreList.push({
                  name: '锁标管理',
                  iconImg: require('@/assets/images/home/icon-sbgl.png'),
                  jumpUrl: '/lockBrands'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_coupon_manage') {
                this.moreList.push({
                  name: '优惠券管理',
                  iconImg: require('@/assets/images/home/icon-yhqgl.png'),
                  jumpUrl: '/couponList'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_collect_manage') {
                this.moreList.push({
                  name: '我的收藏',
                  iconImg: require('@/assets/images/home/icon-wdsc.png'),
                  jumpUrl: '/myCollect'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_browsing_history') {
                this.moreList.push({
                  name: '浏览记录',
                  iconImg: require('@/assets/images/home/icon-fbgl.png'),
                  jumpUrl: '/myBrowseRecord'
                })
                continue;
              }
              if (this.rolePermissionList[i].code == 'menu_notice') {
                this.moreList.push({
                  name: '通知设置',
                  iconImg: require('@/assets/images/home/icon-tzsz.png'),
                  jumpUrl: 'notice/setting'
                })
                continue;
              }
              // TODO
              if (this.rolePermissionList[i].code == 'menu_coupon') {
                this.moreList.push({
                  name: '优惠券中心',
                  iconImg: require('@/assets/images/home/icon-tzsz.png'),
                  jumpUrl: '/couponCenter'
                })
                continue;
              }
            }

          }

        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    // 更多内容展示
    dropdownChange(visible) {
      this.dropVisiable = visible;
    },
    // 点击事件
    handleItem(item) {
      if (!this.hasUserInfo) {
        this.$router.push('/login');
        // this.$store.commit(`home/${type.SHOW_UNLOGIN_DIALOG}`, true);
        return;
      }
      if (!item) return;
      if (this.$route.query.fromPage == 'zl') {
        let openPage = null;
        if (location.host == 'by.ipjy365.com') {
          openPage = `https://zl.ipjy365.com${item.jumpUrl}?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient == 1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
        } else {
          openPage = `https://apre-zl.ipjy365.com${item.jumpUrl}?qdsToken=${base.localStorageGet('tokenValue')}&isClient=${(this.$route.query.isClient == 1 ? 1 : 0) || (this.isClient ? 1 : 0)}&fromPath=${this.isClient ? 'qdsClient' : 'PC'}`;
        }
        window.open(openPage, '_blank')
      } else {
        if (item.jumpUrl == 'notice/setting') {
          this.hideOnClick = true
          this.$refs.dropDown.$data.showPopper = false
          // 通知弹窗
          this.$emit('showNotifyDialog', true, this.$store.state.userInfo.notifyMsgStatus);
          this.hideOnClick = true;
        } else {
          this.$router.push(item.jumpUrl);
          this.hideOnClick = false
          this.$refs.dropDown.$data.showPopper = true
        }


      }

    }
  }
}
</script>

<style lang="scss" >
.home-more {
  // width: 100%;
  position: absolute;
  right: 90px;
  top: 14px;
  text-align: right;
  display: inline-block;
  text-align: right;
  z-index: 9;
  .more-img {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("../../../assets/images/home/home-more.png") no-repeat
      center;
    background-size: contain;
  }
  .el-dropdown-link {
    display: inline-block;
    color: $color-text;
    margin: 0px 16px 0 10px;
    .arrow-down {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: url("../../../assets/images/home/arrow-down-gray.png")
        no-repeat;
      background-size: contain;
    }
    .arrow-up {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: url("../../../assets/images/home/arrow-up-gray.png") no-repeat;
      background-size: contain;
    }
  }
}
.popWrapper {
  margin-right: 20px !important;
}
.popContent {
  padding: 10px 15px;
  font-size: 12px;
  width: 200px;
  text-align: center;
  img {
    width: 100%;
  }
  p {
    margin-bottom: 6px;
    line-height: 16px;
    margin-top: 10px;
  }
}
</style>