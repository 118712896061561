
const recommendList = [
  {
      path: '/recommendList',
      name: 'recommendList',
      component: () => import(/* webpackChunkName: "recommendList" */ '@/views/recommendList/index'),
      meta: {
        name: '标源_商标买卖',
        breadCrumbName: '推荐商标'
      }
  }
];

export default recommendList;
