<template>
  <div
    v-show="show"
    class="toast"
  >
    <div>{{ msg }}</div>
  </div>
</template>

<script>
/**
 * 全局toast组件
 */

export default {
  data() {
    return {
      show: false,
      msg: ''
    };
  },
  methods: {
    showToast(msg, delay = 2000) {
      this.msg = msg;
      this.show = true;
      setTimeout(() => {
        this.msg = '';
        this.show = false;
      }, delay);
    }
  }
};
</script>

<style lang="scss" scoped>
  .toast {
    div {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: $font-size-medium;
      background-color: rgba(0, 0, 0, .6);
      z-index: 9999;
      min-width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 10px;
      padding: 6px;
    }
  }
</style>
