const maintainInfo = {
    namespaced: true,
    state: {
        maintainStatus: '',
        maintainDate: '',
    },
    getters: {},
    mutations: {
      setMaintainStatus(state, payload) {
        state.maintainStatus = payload
      },
      setMaintainDate(state, payload) {
        state.maintainDate = payload
      }
    },
    actions: {}
};

export default maintainInfo;