let transfer = [
	{
		path: '/transferList',
		name: 'transferList',
		component: ()=> import('@/views/transfer/transferList'),
		meta: {
			name: '标源_转让标记管理列表',
			breadCrumbName: '转让标记管理'
		}
	},
	{
		path: '/transferAdd',
		name: 'transferAdd',
		component: ()=> import('@/views/transfer/transferAdd'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '添加待标记商标'
		}
	},
	{
		path: '/transferBrand',
		name: 'transferBrand',
		component: ()=> import('@/views/transfer/transferBrand'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '已转让商标查询'
		}
	}
]
export default transfer;