let lockBrands = [
	{
		path: '/lockBrands',
		name: 'lockBrands',
		component: ()=> import('@/views/lockBrands/lockBrands'),
		meta: {
			name: '标源_标源商标交易查询',
			breadCrumbName: '锁标记录'
		}
	}
]
export default lockBrands;