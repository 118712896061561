import * as type from '../mutation-types';

const myCollect = {
    namespaced: true,
    state: {
        myCollectBatch: false, // 商标经纪人的批量操作标识
        cancleCollectSuccess: false, // 我的收藏列表批量取消收藏成功
        allCollectSelect: false // 我的收藏也全选按钮点击
    },
    getters: {},
    mutations: {
        [type.SET_MY_COLLECT_BATCH](state, payload) {
            state.myCollectBatch = payload;
        },
        [type.CANCLE_BATCH_COLLECT_SUCCESS](state, payload) {
            state.cancleCollectSuccess = payload;
        },
        [type.SET_ALL_SELECT](state, payload) {
            state.allCollectSelect = payload;
        },
    },
    actions: {}
};

export default myCollect;
