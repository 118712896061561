/*
 * @Author: zuochunrui zuochunrui@quandashi.com
 * @Date: 2022-05-06 09:52:26
 * @LastEditors: zuochunrui zuochunrui@quandashi.com
 * @LastEditTime: 2022-05-07 10:32:44
 * @FilePath: \brand-source-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/directives/drag'
import * as mutationType from './store/mutation-types';
import { registerFilters, fenToYuan, fenToYuanFixed } from "@/utils/filters/index";
import preventReClick from '@/utils/directives/preventRepeatClick';
import Toast from '@/utils/extendComponent/toast';
import { JSEncrypt } from 'jsencrypt/lib/JSEncrypt'
import VueClipboard from 'vue-clipboard2';
import download from 'downloadjs';
import base from '@/utils/js/base';

VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard)

Vue.use(ElementUI)
Vue.use(preventReClick)
Vue.use(Toast)

// 引入全局样式文件
import '@/assets/scss/index.scss'

Vue.config.productionTip = false

Vue.prototype.$store = store;

Vue.prototype.$NORMAL_PERMISSION_LIST = ['user','org_slave','pay_org_slave']; // 普通用户 partnerType==0
Vue.prototype.$CERTIFIED_PERMISSION_LIST = ['hhr_rz','org_master_rz','pay_hhr_rz','pay_org_master_rz','internal_hhr_rz','internal_manager']; // 已认证 partnerType==2 || builtinType==1
Vue.prototype.$INTERNAL_PERMISSION_LIST = ['internal_hhr_rz','internal_manager']; // 内部 partnerType==2 && interiorStatus==1 || builtinType==1

// 标源跳H5的配置
let H5URLMap = new Map([
  ['localhost', 'https://pre-h5.quandashi.com/'],
  ['apre-by.ipjy365.com', 'https://pre-h5.quandashi.com/'],
  ['test-by.ipjy365.com', 'https://pre-h5.quandashi.com/'],
  ['by.ipjy365.com', 'https://h5.quandashi.com/']
]);

Vue.prototype.$H5_URL = H5URLMap.get(location.hostname) ? H5URLMap.get(location.hostname) : (location.hostname.indexOf('apre') > - 1 ? 'http://pre-h5.quandashi.com/' : 'http://h5.quandashi.com/');

let QDSJUMPMap = new Map([
  ['localhost', 'https://apre-www.quandashi.com/'],
  ['apre-by.ipjy365.com', 'https://apre-www.quandashi.com/'],
  ['test-by.ipjy365.com', 'https://test-www.quandashi.com/'],
  ['by.ipjy365.com', 'https://www.quandashi.com/']
]);

Vue.prototype.$QDSURL = QDSJUMPMap.get(location.hostname) ? QDSJUMPMap.get(location.hostname) : (location.hostname.indexOf('apre') > - 1 ? 'https://apre-www.quandashi.com/' : 'https://www.quandashi.com/');

let UPLOADOSSMap = new Map([
  ['localhost', 'http://tm-image.oss-cn-beijing.aliyuncs.com/'],
  ['apre-by.ipjy365.com', 'http://tm-image.oss-cn-beijing.aliyuncs.com/'],
  ['test-by.ipjy365.com', 'http://tm-image.oss-cn-beijing.aliyuncs.com/'],
  ['by.ipjy365.com', 'http://tm-files.oss-cn-beijing.aliyuncs.com/']
]);
// Vue.prototype.$UPLOAD_OSS_URL = UPLOADOSSMap.get(location.hostname) ? UPLOADOSSMap.get(location.hostname) : 'http://tm-files.oss-cn-beijing.aliyuncs.com/';
Vue.prototype.$UPLOAD_OSS_URL = 'http://tm-files.oss-cn-beijing.aliyuncs.com/';

// 注册全局过滤器
let registerFiltersArr = [
  {
    filter: "fenToYuan",
    filterFun: fenToYuan
  },
  {
    filter: "fenToYuanFixed",
    filterFun: fenToYuanFixed
  }
];
registerFilters(registerFiltersArr);

// 定义上传文件地址
const API_AGENTCY_NEW_URL = 'http://phoenix.quandashi.com';
const API_AGENTCY_UPLOAD_URL = 'http://phpupload.quandashi.com';
Vue.prototype.$normalUploadUrl = API_AGENTCY_NEW_URL;
Vue.prototype.$uploadUrl = API_AGENTCY_UPLOAD_URL;

Vue.prototype.$encruption = function (obj) {
  let encrypt = new JSEncrypt()
  // 公钥
  encrypt.setPublicKey(
     'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCdN4fI/a/KBfVG/mZG9s6tBnGRVd5P1QpLUcZJuJn1fPZPpezoiRmUgZD0wUVIWRgvOmjCf1rYX9VtWq2gAjCg3ElVrR4R4GhzHCqjsI+AVTwnvKA6r8WOcC0FMewaWm+shuhgoRAXv0znZ8bDqVV5J27CAldLU+T5XLpUTHuw+wIDAQAB'
  )
  return encrypt.encrypt(obj)
}
window.addEventListener("message", function(event) {
  try {
    if (event.data && event.data == 'logout') {
      console.log(event);
      clearUserLoginInfo();
    }
  } catch (error) {
    console.log(event);
    if (event.data && event.data && event.data.data == 'logout') {
      clearUserLoginInfo();
    }
  }
}, false);

function clearUserLoginInfo() {
  // localStorage.removeItem('storageClientUserIdStr');
  localStorage.removeItem('storageClientUserType');
  localStorage.removeItem('storageClientSource');
  localStorage.removeItem('storageIsFromClient');
  localStorage.removeItem('storageClientAgentOrganName');
  localStorage.removeItem('tokenValue');
  localStorage.removeItem('tokenName');
  localStorage.removeItem('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  localStorage.removeItem('QDS_AGENT_ORGAN_INFO_'+document.domain);
  base.clearCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain);
  base.clearCookie('QDS_AGENT_ORGAN_INFO_'+document.domain);
  base.clearCookie('QDS_COOKIE_'+document.domain);
  store.commit('userInfo/setIsClient',false);
}

Vue.prototype.downloadFile = function (url, fileName) {
  download(url, fileName, "text/plain");
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
