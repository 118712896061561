/*
 * @Author: your name
 * @Date: 2022-04-28 10:47:10
 * @LastEditTime: 2022-05-18 13:45:25
 * @LastEditors: zuochunrui zuochunrui@quandashi.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \brand-source-web\src\apis\home\user.js
 */
import BaseRequest from "@/utils/http/index";

let request = new BaseRequest('');
let prefix = '/api/source';
let prefixOrg = '/source';
let bsPreFix = '/bsapi';

// 获取用户身份
export const getHhrInformation = (params) => {
  return request.post(bsPreFix + '/user/hhrInformation', params);
};

// 发布时获取发布人员信息
export const getSourceContact = (params) =>{
  return request.post(prefixOrg + '/getSourceContact',params)
}
export const findByUserId = (params) => {
  return request.post(prefix + '/contact/findByUserId',params)
}
// 查询 是否可更新标源名称状态
export const queryIsUpdateStatus = (params) => {
  return request.post(prefix + '/contact/queryIsUpdateStatus',params)
}
// 查询 站内信数量
export const getInnerMsgCount = (params) => {
  return request.post(bsPreFix + '/common/getInnerMsgCount',params)
}
// 查询通知设置
export const queryMsgStatus = params => {
  return request.post(prefix+'/msgSetting/queryMsgStatus',params)
}
// 保存通知设置
export const updateMsgStatus = params => {
  return request.post(prefix+'/msgSetting/updateMsgStatus',params)
}
// 查询维护状态
export const querySourceMaintain = params => {
  return request.post(prefix+'/release/querySourceMaintain',params)
}

// 查询 经纪人类型 内部或外部
export const getAgentTypeById = (params) => {
  // 1 -- 内部合伙  2 -- 外部合伙   3 -- 机构合伙
  return request.post(prefix + '/agent/getAgentType',params)
};

// 查询用户身份信息
export const synchronizeUser = (params) => {
  return request.post(prefix + '/user/synchronizeUser',params)
};

// 查询用户的角色权限
export const getRoleMenu = (params) => {
  // return request.post(prefix + '/role/getRoleMenu',params)
  return request.post(prefix + '/role/getRoleMenuNew',params)
};

// 根据条件获取用户信息
export const queryUserInfo = (params) => {
  return request.post(prefix + '/user/queryUserInfo',params)
};

// 获取灰度账号
export const queryConstantOptimizeInfo = (params) => {
  return request.post(bsPreFix + '/legend4/common/queryConstantOptimizeInfo', params);
};