const home = [
  {
      path: '/home',
      name: 'home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '@/views/home/home'),
      meta: {
        name: '商标买卖-标源交易-商标交易查询-标源',
        breadCrumbName: '全网闲置转让商标查询'
      }
  },
];

export default home
