<template>
  <div
    id="app"
    ref="main"
    :class="{'app-white': whiteBgRouter.includes($route.path)}"
  >
    <template v-if="$route.path != '/login' && $route.path != '/passwordForget' && $route.path != '/register' && $route.path != '/nineGridShare'">
      <!-- 头部  客户端模式隐藏 -->
      <home-header
        v-if="!isClient && !manualJudgeIsClient"
        :style="{'marginBottom': noMarginBRouter.includes($route.path) ? '0' : '20px'}"
        @showNotifyDialog="handleDialogClick(arguments)"
      ></home-header>
      <div
        class="render-payload"
        :style="{width: isClient || manualJudgeIsClient || fullWidthRouter.includes($route.path) ? '100%' : '',paddingLeft: isClient || manualJudgeIsClient ? '20px' : '', paddingRight: isClient || manualJudgeIsClient ? '20px' : ''}"
      >
        <!-- 面包屑 客户端模式隐藏 -->
        <g-bread-crumb-info v-if="$route.path != '/agreementDetail' && ($route.path == '/brandInfo' || $route.path == '/brokerHome')"></g-bread-crumb-info>
        <g-bread-crumb v-if="$route.path != '/agreementDetail' && $route.path != '/brandInfo' && $route.path != '/brokerHome'"></g-bread-crumb>
        <home-more
          v-if="partnerType > 0 && rolePermissionList.some(obj=>obj.code=='menu') && !isClient && !manualJudgeIsClient"
          @showNotifyDialog="handleDialogClick(arguments)"
        ></home-more>
        <router-view></router-view>
      </div>
    </template>
    <template v-else>
      <div class="render-payload1">
        <router-view></router-view>
      </div>
    </template>
    <!-- 右侧导航栏--与现有保持一致先隐藏 -->
    <!-- <menu-bar></menu-bar> -->
    <!-- 底部 公司版权和地址栏 -->
    <home-bottom v-if="($route.path == '/home' || $route.path == '/brandInfo') && !isClient && !manualJudgeIsClient"></home-bottom>
    <!-- 底部全选模块 -->
    <select-all v-if="showAllSelect && ($route.path == '/home' || $route.path == '/boutiqueBrand' || $route.path == '/myCollect' || $route.path == '/brokerHome' || $route.path == '/comBrokerHome')"></select-all>
    <zl-select-all v-if="showZlAllSelect && ($route.path == '/zlBrokerHome' || $route.path == '/comBrokerHome')"></zl-select-all>
    <HomeNotifySetting
      :visible.sync="dialogVisible"
      :notify-msg-status="notifyMsgStatus"
    ></HomeNotifySetting>

    <div
      v-if="$route.path != '/login' && $route.path != '/passwordForget' && $route.path != '/register' && $route.path != '/nineGridShare'"
      class="fixedRightTools"
    >
      <div
        class="toolItem backTop"
        @click="backTop()"
      >
        回到<br />顶部
      </div>
      <el-popover
        placement="left-end"
        width="200"
        trigger="hover"
        popper-class="popWrapper"
        :offset="50"
      >
        <!-- <span slot="reference">{{ item.name }}</span> -->
        <div
          slot="reference"
          class="toolItem xcx"
        >
          标源<br />小程序
        </div>
        <div class="popContent">
          <img
            src="@/assets/images/home/ewm.png"
            alt=""
          />
          <p class="line">
            微信扫一扫
          </p>
          <p>随时随地查询闲置商标</p>
        </div>
      </el-popover>
      <div v-if="partnerType > 0" class="toolItem helpCenter" @click="toHelpPage()">
        <img
          class="newTag"
          src="@/assets/images/home/icon-new-tag.png"
          alt=""
        />
        帮助<br />中心
      </div>
    </div>

    <!-- <el-backtop
      :bottom="120"
    >
      <div
        class="bckTop"
      >
        <img
          src="@/assets/images/home/img-back-top.png"
          alt=""
        />
      </div>
    </el-backtop> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HomeHeader from './views/home/components/home-header';
import MenuBar from "@/components/menuBar";
import SelectAll from '@/components/select-all';
import ZlSelectAll from '@/views/brokerHome4zl/components/zl-select-all';
import GBreadCrumb from '@/components/breadcrumb'
import GBreadCrumbInfo from '@/components/breadcrumbInfo'
import HomeNotifySetting from "@/views/home/components/home-notify-setting";
import HomeBottom from './views/home/components/home-bottom';
import HomeMore from '@/views/home/components/home-more'
import base from '@/utils/js/base';

export default {
  components: {
    HomeHeader,
    // MenuBar,
    HomeMore,
    SelectAll,
    ZlSelectAll,
    HomeBottom,
    GBreadCrumb,
    GBreadCrumbInfo,
    HomeNotifySetting
  },
  data() {
    return {
      whiteBgRouter: ['/nineGridShare'], // 背景为白色的
      fullWidthRouter: ['/helpCenter'], // 全屏宽度
      noMarginBRouter: ['/helpCenter','/brandInfo','/brokerHome'], // 底部不贴边
      dialogVisible: false,
      notifyMsgStatus: {},
      manualJudgeIsClient: 0,
    }
  },
  computed: {
    ...mapState('home', {
      showAllSelect: state => state.batchShare
    }),
    ...mapState('patent', {
      showZlAllSelect: state => state.zlBatchShare
    }),

    ...mapState('userInfo', {
      agentType: state => state.agentType,
      partnerType: state => state.partnerType,
      payStatus: state => state.payStatus,
      interiorStatus: state => state.interiorStatus,
      accountType: state => state.accountType,
      builtinType: state => state.builtinType,
      isClient: state => state.isClient,
      hasUserInfo: (state) => state.hasLoginInfo,
      rolePermissionList: state => state.rolePermissionList,
    }),
  },
  watch: {
    $route(newVal){
      console.log(newVal);
      console.log(this.isClient);
      this.manualJudgeIsClient = newVal.query && +newVal.query.isClient || 0;
      console.log(this.manualJudgeIsClient);
      let main = this.$refs.main;
      main.scrollTo(0,0);
    }
  },
  methods: {
    handleDialogClick(param){
      this.dialogVisible = param[0];
      this.notifyMsgStatus = param[1];
    },
    backTop(){
      var target = 0,leader = 0,timer = null;
      clearInterval(timer);
      timer = setInterval(function(){
        // target 目标值 leader初始时是滚动的高度
        var step = (target - leader)/10;
        // 如果大于0向上取整小于0向下取整
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        // leader发生改变
        leader = leader + step;
        // 返回到哪一个地方
        window.scrollTo(0,leader);
        if (leader == target) {
        clearInterval(timer);
        }
      },30)
    },
    toHelpPage(){
      // let href = null;
      // if(location.host=='by.ipjy365.com'){
      //   href = `https://hhr.quandashi.com/support-center?type=3`;
      // } else {
      //   href = `https://apre-hhr.quandashi.com/support-center?type=3`;
      // }
      // window.open(href);
      if (!this.hasUserInfo) {
        this.$router.push("/login");
        return;
      } else {
        this.$router.push("/helpCenter");
      }
    },
  }
}
</script>
<style lang="scss" scoped>


#app {
  min-height: 100vh;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f6f8fa;
  font-family: MicrosoftYaHei;
  // padding-bottom: 130px;
  //min-height: 1300px;
}
  .render-payload {
    margin: 0 auto 0;
    width: 1280px;
    padding-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    // min-height: calc(100vh - 60px);
  }
  .render-payload1{
    width: 100%;
    margin-bottom: 20px;
  }
  #app.app-white {
    background-color: #fff;
  }
  ::v-deep .el-backtop{
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 2px;
    .bckTop{
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 0 6px rgba(0,0,0, .12);
      text-align: center;
      color: #ff7200;
      img{
        width: 24px;
        height: 24px;
        position: relative;
        top: 5px;
      }
    }
  }
  .fixedRightTools{
    position: fixed;
    right: 10px;
    bottom: 200px;
    width: 42px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(11,17,60,0.1);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 0 3px;
    box-sizing: border-box;
    .toolItem{
      color: #666666;
      border-bottom: 1px solid #E4E4E4;
      line-height: 16px;
      cursor: pointer;
      // &:last-child{
      //   border-bottom: none;
      // }
      &:hover{
        color: #ff7200;
      }
    }
    .backTop{
      padding: 30px 0 15px;
      background: url('@/assets/images/home/icon-back-top.png') no-repeat ;
      background-size: 26px;
      background-position-x: center;
      background-position-y: 10px;
      &:hover{
        background: url('@/assets/images/home/icon-back-top-active.png') no-repeat ;
        background-size: 26px;
        background-position-x: center;
        background-position-y: 10px;
      }
    }
    .xcx{
      padding: 40px 0 15px;
      background: url('@/assets/images/home/icon-xcx.png') no-repeat ;
      background-size: 26px;
      background-position-x: center;
      background-position-y: 10px;
      &:hover{
        background: url('@/assets/images/home/icon-xcx-active.png') no-repeat ;
        background-size: 26px;
        background-position-x: center;
        background-position-y: 10px;
      }
    }
    .helpCenter{
      position: relative;
      padding: 40px 0 15px;
      background: url('@/assets/images/home/icon-help.png') no-repeat ;
      background-size: 26px;
      background-position-x: center;
      background-position-y: 10px;
      &:hover{
        background: url('@/assets/images/home/icon-help-active.png') no-repeat ;
        background-size: 26px;
        background-position-x: center;
        background-position-y: 10px;
      }
      .newTag{
        position: absolute;
        width: 37px;
        height: 15px;
        left: -32px;
        top: 2px;
      }
    }
  }
</style>

<style lang="scss" >
  .popWrapper{
    margin-right: 5px!important;
    margin-bottom: -50px!important;
  }
  .popContent{
    padding: 10px 15px;
    font-size: 12px;
    width: 200px;
    text-align: center;
    img{
      width: 100%;
    }
    .line{
      font-weight: bold;
      color: #222222;
      margin-bottom: 0px;
      font-size: 14px;
    }
    p{
      margin-bottom: 6px;
      line-height: 16px;
      margin-top: 10px;
    }
  }
</style>