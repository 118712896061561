const myCollect = [
  {
      path: '/myCollect',
      name: 'myCollect',
      component: () => import(/* webpackChunkName: "myCollect" */ '@/views/myCollect/index'),
      meta: {
        name: '标源_商标买卖',
        breadCrumbName: '收藏的商标'
      }
  }
];

export default myCollect;
