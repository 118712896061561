/**
 * 各个模块mutationType集合
 *  */ 

// 全批量分享按钮点击
export const SET_BATCH_SHARE = 'set_batch_share';

// 点击取消批量分享清除已选中的商标
export const CLEAR_SINGLE_BRAND = 'clear_single_brand';

// 底部footer全选按钮点击
export const SET_ALL_SELECT = 'set_all_select';

// 底部footer全选个数
export const SET_SELECT_COUNT = 'set_select_count';

// 设置已选商标
export const SET_SELECTED_BRAND_ARR = 'set_selected_brand_arr';

// 商标经纪人主页 在售标源、成交标源批量操作点击
export const SET_BROKER_BATCH = 'set_broker_batch';

/**
 * common
 * 尼斯45大类
 */
export const SET_NICE_LIST = 'set_nice_list';
export const SET_CHECK_ALL_FLAG = 'set_check_all_flag';
export const SET_CHECK_ALL_REMOVE_DUL_FLAG = 'set_check_remove_dul_flag';
export const SET_USER_IDENTITY = 'set_user_identity';

// 展示未登录弹窗
export const SHOW_UNLOGIN_DIALOG = 'show_unlogin_dialog';

// 设置合伙人信息
export const SET_BROKER_INFO = 'set_broker_info';

/**
 * myCollect
 * 我的收藏
 */
 export const SET_MY_COLLECT_BATCH = 'set_my_collect_batch';

// 批量取消收藏标志
export const CANCLE_BATCH_COLLECT_SUCCESS = 'cancle_batch_collect_success';

/**
 * home
 */
export const SET_HIDE_EXPORT_BTN = 'set_hide_export_btn';
export const SET_NEGOTIABLE = 'set_negotiable';
export const SET_ORIGIN_URL = 'set_origin_url'; // 改价是否展示分享成功弹窗的分享源

// 专利相关
// 全批量分享按钮点击
export const SET_ZL_BATCH_SHARE = 'set_zl_batch_share';
// 底部footer全选按钮点击
export const SET_ZL_ALL_SELECT = 'set_zl_all_select';
// 底部footer全选个数
export const SET_ZL_SELECT_COUNT = 'set_zl_select_count';
// 设置已选专利
export const SET_SELECTED_PATENT_ARR = 'set_selected_patent_arr';
export const SET_ZL_HIDE_EXPORT_BTN = 'set_zl_hide_export_btn';