/*
 * @Author: your name
 * @Date: 2022-04-25 11:39:49
 * @LastEditTime: 2022-04-25 14:52:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \brand-source-web\src\utils\js\base.js
 */
'use strict';
import store from "../../store/index";
var base;
base = {
    //判断字符串是否符合手机号码格式 错误提示:号码格式错误,固话需加区号和符号‘-’
    checkPhone: function (tel) {
        tel = tel.replace(/&nbsp;/ig, "");
        tel = tel.trim();
        var r = /^((0\d{2,3}-\d{7,8})|(1+\d{10}))$/;

        if (r.test(tel)) {
            return true;
        }

        return false;
    },
    isEmptyObject: function (obj) {
        for (var k in obj) {

            return false;
        }
        return true;
    },
    //判断统一社会信用代码
    checkCreditcode: function (code) {
        var code = $.trim(code).replace(/\n/g, "");
        if(!(/^(\w{15}|\w{18})$/g).test(code)){
            layer.msg('请填写正确的统一社会信用代码！');
            return false;
        }
        return true;
    },
    getBaseParam: function () {
        return {
            userIde: store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId || '',
            userName: store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userName || '',
            userPhone: store.state.userInfo.agentInfo && store.state.userInfo.agentInfo.account || ''
        }
    },

    isJsonObj: function(str) {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch(e) {
        }
        return false;
    },
    // 存储localStorage，设置过期时间
    localStorageSet: function(lname, ldata, exDay){
        const obj = {
            ldata,
            expire: exDay ? new Date().getTime() + (1000 * 60 * 60 * 24 * exDay) : null
        };
        localStorage.setItem(lname, JSON.stringify(obj));
    },
    // 获取localStorage，过期则返回null
    localStorageGet: function(lname) {
        const storage = localStorage.getItem(lname);
        const time = new Date().getTime();
        let result = null;
        if (storage && this.isJsonObj(storage)) {
            const obj = JSON.parse(storage);
            if (!obj.expire || time < obj.expire) {
                result = obj.ldata;
            } else {
                localStorage.removeItem(lname);
            }
        }
        return result;
    },
    //操作cookie
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = exdays ? "expires=" + d.toUTCString()+';' : '';
        var cookie_domain = "domain=" + document.domain;
        document.cookie = cname + "=" + cvalue + "; " + expires + "path=/;"+cookie_domain;
    },
    getCookie: function (key) {
        var getCookie = document.cookie.replace(/[ ]/g, "");
        var arrCookie = getCookie.split(";")
        var tips;
        for (var i = 0; i < arrCookie.length; i++) {
            var arr = arrCookie[i].split("=");
            if (key == arr[0]) {
                tips = arr[1];
                break;
            }
        }
        return tips;
    },
    clearCookie: function (name) {
        this.setCookie(name, "", -1);
    },
    isRepeat: function(arr){
        arr = arr.sort();
        for(var i=0;i<arr.length;i++) {
            if (arr[i] == arr[i+1]) {
                return false;
            }
        }
        return true;
    },
    // input值双向绑定
    listenInputText: function (input, callback) {
        Object.defineProperties(input, '_value', {
            configurable: true,
            set: function (value) {
                this.value = value;
                callback();
            },
            get: function () {
                return this.value;
            }
        })
    },
    replaceEmpty(name,separator){
        if(!name || name=='' || name==null || name==undefined){
            return separator
        } else {
            return name
        }
    },
    cgList: [
        {
            "cgId":"1",
            "cgNum":"01",
            "cgName":"化学原料",
            "name":"1",
            "value":"01类 化学原料"
        },
        {
            "cgId":"952",
            "cgNum":"02",
            "cgName":"颜料油漆",
            "name":"2",
            "value":"02类 颜料油漆"
        },
        {
            "cgId":"1102",
            "cgNum":"03",
            "cgName":"日化用品",
            "name":"3",
            "value":"03类 日化用品"
        },
        {
            "cgId":"1415",
            "cgNum":"04",
            "cgName":"燃料油脂",
            "name":"4",
            "value":"04类 燃料油脂"
        },
        {
            "cgId":"1552",
            "cgNum":"05",
            "cgName":"医药",
            "name":"5",
            "value":"05类 医药"
        },
        {
            "cgId":"2028",
            "cgNum":"06",
            "cgName":"金属材料",
            "name":"6",
            "value":"06类 金属材料"
        },
        {
            "cgId":"2533",
            "cgNum":"07",
            "cgName":"机械设备",
            "name":"7",
            "value":"07类 机械设备"
        },
        {
            "cgId":"3596",
            "cgNum":"08",
            "cgName":"手工器械",
            "name":"8",
            "value":"08类 手工器械"
        },
        {
            "cgId":"10455",
            "cgNum":"09",
            "cgName":"科学仪器",
            "name":"9",
            "value":"09类 科学仪器"
        },
        {
            "cgId":"3900",
            "cgNum":"10",
            "cgName":"医疗器械",
            "name":"10",
            "value":"10类 医疗器械"
        },
        {
            "cgId":"4137",
            "cgNum":"11",
            "cgName":"灯具空调",
            "name":"11",
            "value":"11类 灯具空调"
        },
        {
            "cgId":"4533",
            "cgNum":"12",
            "cgName":"运输工具",
            "name":"12",
            "value":"12类 运输工具"
        },
        {
            "cgId":"4874",
            "cgNum":"13",
            "cgName":"军火烟火",
            "name":"13",
            "value":"13类 军火烟火"
        },
        {
            "cgId":"4970",
            "cgNum":"14",
            "cgName":"珠宝钟表",
            "name":"14",
            "value":"14类 珠宝钟表"
        },
        {
            "cgId":"5083",
            "cgNum":"15",
            "cgName":"乐器",
            "name":"15",
            "value":"15类 乐器"
        },
        {
            "cgId":"5184",
            "cgNum":"16",
            "cgName":"办公用品",
            "name":"16",
            "value":"16类 办公用品"
        },
        {
            "cgId":"5663",
            "cgNum":"17",
            "cgName":"橡胶制品",
            "name":"17",
            "value":"17类 橡胶制品"
        },
        {
            "cgId":"5824",
            "cgNum":"18",
            "cgName":"皮革皮具",
            "name":"18",
            "value":"18类 皮革皮具"
        },
        {
            "cgId":"5961",
            "cgNum":"19",
            "cgName":"建筑材料",
            "name":"19",
            "value":"19类 建筑材料"
        },
        {
            "cgId":"6281",
            "cgNum":"20",
            "cgName":"家具",
            "name":"20",
            "value":"20类 家具"
        },
        {
            "cgId":"6640",
            "cgNum":"21",
            "cgName":"厨房洁具",
            "name":"21",
            "value":"21类 厨房洁具"
        },
        {
            "cgId":"7054",
            "cgNum":"22",
            "cgName":"绳网袋蓬",
            "name":"22",
            "value":"22类 绳网袋蓬"
        },
        {
            "cgId":"7179",
            "cgNum":"23",
            "cgName":"纱线丝",
            "name":"23",
            "value":"23类 纱线丝"
        },
        {
            "cgId":"7234",
            "cgNum":"24",
            "cgName":"布料床单",
            "name":"24",
            "value":"24类 布料床单"
        },
        {
            "cgId":"7413",
            "cgNum":"25",
            "cgName":"服装鞋帽",
            "name":"25",
            "value":"25类 服装鞋帽"
        },
        {
            "cgId":"7637",
            "cgNum":"26",
            "cgName":"钮扣拉链",
            "name":"26",
            "value":"26类 钮扣拉链"
        },
        {
            "cgId":"7796",
            "cgNum":"27",
            "cgName":"地毯席垫",
            "name":"27",
            "value":"27类 地毯席垫"
        },
        {
            "cgId":"7825",
            "cgNum":"28",
            "cgName":"健身器材",
            "name":"28",
            "value":"28类 健身器材"
        },
        {
            "cgId":"7956",
            "cgNum":"29",
            "cgName":"食品",
            "name":"29",
            "value":"29类 食品"
        },
        {
            "cgId":"8423",
            "cgNum":"30",
            "cgName":"方便食品",
            "name":"30",
            "value":"30类 方便食品"
        },
        {
            "cgId":"8797",
            "cgNum":"31",
            "cgName":"农林生鲜",
            "name":"31",
            "value":"31类 农林生鲜"
        },
        {
            "cgId":"8983",
            "cgNum":"32",
            "cgName":"啤酒饮料",
            "name":"32",
            "value":"32类 啤酒饮料"
        },
        {
            "cgId":"9051",
            "cgNum":"33",
            "cgName":"酒",
            "name":"33",
            "value":"33类 酒"
        },
        {
            "cgId":"9091",
            "cgNum":"34",
            "cgName":"烟草烟具",
            "name":"34",
            "value":"34类 烟草烟具"
        },
        {
            "cgId":"9138",
            "cgNum":"35",
            "cgName":"广告销售",
            "name":"35",
            "value":"35类 广告销售"
        },
        {
            "cgId":"9261",
            "cgNum":"36",
            "cgName":"金融物管",
            "name":"36",
            "value":"36类 金融物管"
        },
        {
            "cgId":"9360",
            "cgNum":"37",
            "cgName":"建筑修理",
            "name":"37",
            "value":"37类 建筑修理"
        },
        {
            "cgId":"9514",
            "cgNum":"38",
            "cgName":"通讯服务",
            "name":"38",
            "value":"38类 通讯服务"
        },
        {
            "cgId":"9556",
            "cgNum":"39",
            "cgName":"运输贮藏",
            "name":"39",
            "value":"39类 运输贮藏"
        },
        {
            "cgId":"9674",
            "cgNum":"40",
            "cgName":"材料加工",
            "name":"40",
            "value":"40类 材料加工"
        },
        {
            "cgId":"9813",
            "cgNum":"41",
            "cgName":"教育娱乐",
            "name":"41",
            "value":"41类 教育娱乐"
        },
        {
            "cgId":"9945",
            "cgNum":"42",
            "cgName":"科技服务",
            "name":"42",
            "value":"42类 科技服务"
        },
        {
            "cgId":"10041",
            "cgNum":"43",
            "cgName":"餐饮住宿",
            "name":"43",
            "value":"43类 餐饮住宿"
        },
        {
            "cgId":"10080",
            "cgNum":"44",
            "cgName":"医疗园艺",
            "name":"44",
            "value":"44类 医疗园艺"
        },
        {
            "cgId":"10150",
            "cgNum":"45",
            "cgName":"社会服务",
            "name":"45",
            "value":"45类 社会服务"
        }
    ],
    /**
     * 根据身份证号得到姓别和精确计算年龄
     */
    analyzeIDCard: function (IDCard){
        //获取用户身份证号码
        var userCard = IDCard;
        //如果身份证号码为undefind则返回空
        if(!userCard){
            return false;
        }
        //获取出生年月日
        var yearBirth = userCard.substring(6,10);
        var monthBirth = userCard.substring(10,12);
        var dayBirth = userCard.substring(12,14);
        //获取当前年月日并计算年龄
        var myDate = new Date();
        var monthNow = myDate.getMonth() + 1;
        var dayNow = myDate.getDate();
        var age = myDate.getFullYear() - yearBirth;
        if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
            age--;
        }
        if (age >= 18) { return true; }
        return false;
    },

};

export default base;
