<template>
  <div class="header">
    <div class="header-cont">
      <div
        class="header-logo"
        @click="handleClickLogo"
      ></div>
      <div class="header-nav">
        <div
          class="nav-left"
          @click="handleOperatorPlatform"
        >
          标源-全网商标交易查询
        </div>
        <div
          v-if="hasUserInfo"
          class="nav-right"
        >
          <span 
            class="nav-right-bell"
            @click="goMessage()"  
          >
            <el-badge
              v-if="unreadCount > 0"
              :value="unreadCount"
              class="badgeItem" 
            >
              <img
                src="@/assets/images/home/home-bell.png"
                class="nav-right-bell"
              />
            </el-badge>
            <img
              v-else
              src="@/assets/images/home/home-bell.png"
            />
          </span>
          <div class="nav-right-user">
            <div>
              <el-dropdown
                placement="bottom-end"
                class="user-drop"
                :hide-on-click="hideOnClick"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  <img
                    class="avatar"
                    :src="getUserAvatar(userIdentity.avatar)"
                    alt=""
                  />
                  <span class="username">{{ userIdentity.userName }}</span>
                  <img
                    class="more"
                    src="@/assets/images/home/icon-more-arrow.png"
                    alt=""
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item,index) in personDropDownList"
                    :key="index"
                    :command="item.jumpUrl"
                  >
                    <template v-if="item.jumpUrl && item.jumpUrl !=''">
                      <img
                        class="drop-icon"
                        :src="item.iconImg"
                        alt=""
                      /> {{ item.name }}
                    </template>
                    <template v-else>
                      <img
                        class="drop-icon"
                        :src="item.iconImg"
                        @mousemove="showDropImg = true"
                        @mouseleave="showDropImg = !showDropImg"
                      /> <span
                        @mousemove="showDropImg = true"
                        @mouseleave="showDropImg = !showDropImg"
                      >{{ item.name }}</span>
                      <div
                        v-if="showDropImg"
                        style="position: absolute; top: 100px; left: -210px; background: #fff; text-align: center; padding: 10px;"
                      >
                        <img
                          src="../../../assets/images/home/ewm.png"
                          alt=""
                        />
                        <p>扫一扫，随时随地查询闲置商标</p>
                      </div>
                    </template>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- <div
            v-if="showNavTip && $route.path == '/home'"
            class="nav-right-tip"
          >
            更多功能已转移到这儿啦~
            <i
              class="el-icon-close"
              style="cursor: pointer;"
              @click="showNavTip = false"
            ></i>
          </div> -->
        </div>
        <div
          v-else
          class="nav-right"
        >
          <el-button
            class="signBtn"
            type="primary"
            size="small"
            @click="toSign"
          >
            登录
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ENV_MAP = new Map([
  ['test-by.ipjy365.com', 'https://test-www.quandashi.com'],
  ['apre-by.ipjy365.com', 'https://apre-www.quandashi.com'],
  ['by.ipjy365.com', 'https://www.quandashi.com']
]);

import { mapState } from 'vuex';
import { logout } from "@/apis/userCenter/userCenter";
import base from '@/utils/js/base';
import * as type from '@/store/mutation-types';
export default {
  data() {
    return {
      imgPrefix: 'https://tm-files.oss-cn-beijing.aliyuncs.com',
      personDropDownList: [
        {
          name: '退出登录',
          iconImg: require('@/assets/images/home/icon-tcdl.png'),
          jumpUrl: '/index/logout'
        }
      ],
      showDropImg: false,
      hideOnClick: false,
      showNavTip: true
    }
  },
  computed: {
    ...mapState('userInfo', {
      hasUserInfo: state => state.hasLoginInfo,
      unreadCount: state => state.unreadCount,
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      isClient: state => state.isClient
    }),
    ...mapState('maintainInfo', {
      maintainStatus: state => state.maintainStatus,
      maintainDate: state => state.maintainDate
    }),
    ...mapState('common', {
      userIdentity: state => state.userIdentity
    }),
  },
  watch: {
    
  },
  mounted(){
    
  },
  methods: {
    getUserAvatar(url) {
      if (!url) {
        return require('@/assets/images/home/avatar.png');
      }
      if (url[0] == '/') {
        return this.imgPrefix + url;
      }
      return this.imgPrefix + '/' + url;
    },
    getUserImg(url) {
      if (url.indexOf('http' > -1)) {
        return url;
      } else if (url[0] == '/') {
        return this.imgPrefix + url;
      }
      return this.imgPrefix + '/' +url;
    },
    handleCommand(command){
      console.log(command)
      if (!command) return;
      if (command == 'notice/setting') {
        this.$emit('showNotifyDialog', true, this.$store.state.userInfo.notifyMsgStatus);
        this.hideOnClick = true;
      } else if(command=='/index/logout'){
        this.logout();
      } else {
        this.$router.push({path: command, query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
      }
      // if(command.indexOf(".html") > -1){
      //   window.location.href = window.location.origin + command;
      // } else if(command.indexOf('.com') > -1){
      //   window.location.href = command;
      // } else if(command=='/index/logout'){
      //   this.logout();
      // } else {
      //   this.$router.push(command)
      // }
    },
    logout(){
      var that = this;
      let params = {
        appKey: 'quandashi4106565062',
        signMethod: 'md5',
        inputType: 1,
        v: '1.0',
        executor: "140fcf1dfb9b6368d33e1d16d14fcad3",
        inputType: 1,
        format: 'json',
        timestamp: new Date().getTime(),
        sign: new Date().getTime(),
      }
      logout(params).then(res=>{
        that.$toast.showToast('退出成功')
        that.$nextTick(()=>{
          localStorage.removeItem('storageClientUserType');
          localStorage.removeItem('storageClientSource');
          localStorage.removeItem('storageIsFromClient');
          localStorage.removeItem('storageClientAgentOrganName');
          localStorage.removeItem('tokenValue');
          localStorage.removeItem('tokenName');
          localStorage.removeItem('QDS_LOGIN_INFO_OFFICE_'+document.domain);
          localStorage.removeItem('QDS_AGENT_ORGAN_INFO_'+document.domain);
          let info = {
            agentOrWriter: '',
            operatorId: "",
            operatorName: "",
            userId: "",
            userImg: "",
            userName: ""
          }
          that.$store.commit('userInfo/setHasLoginInfo', false);
          that.$store.commit('userInfo/setLoginInfoOffice', info);
          
          that.$store.commit('userInfo/setAgentType', -1);
          that.$store.commit('userInfo/setPartnerType', -1);
          that.$store.commit('userInfo/setPayStatus', -1);
          that.$store.commit('userInfo/setInteriorStatus', -1);
          that.$store.commit('userInfo/setAccountType', -1);
          that.$store.commit('userInfo/setBuiltinType', -1);
          let clearUserIdentity = {
            agentType: 0,
            avatar: '',
            userName: '',
            userType: 0
          };
          that.$store.commit(`common/${type.SET_USER_IDENTITY}`, clearUserIdentity);
          base.clearCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain);
          base.clearCookie('QDS_AGENT_ORGAN_INFO_'+document.domain);
          base.clearCookie('QDS_COOKIE_'+document.domain);
          if(window.location.href.indexOf('home') > -1){
            this.$router.go(0);
          } else {
            this.$router.push({path: '/home', query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}})
          }
        })
      })
    },
    goMessage(){
      this.$router.push('/messageList');
    },
    // 登录
    toSign(){
      sessionStorage.setItem('qdsHistoryUserId',this.$store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId || '');
      sessionStorage.setItem('qdsHistoryRoutePath',this.$router.history.current.path)
      sessionStorage.setItem('qdsHistoryRouteQuery',this.$router.history.current.query.userIdStr?'{}':JSON.stringify(this.$router.history.current.query))
      localStorage.removeItem('storageClientUserType');
      localStorage.removeItem('storageClientSource');
      localStorage.removeItem('storageIsFromClient');
      localStorage.removeItem('storageClientAgentOrganName');
      base.clearCookie('QDS_LOGIN_INFO_OFFICE_'+document.domain);
      base.clearCookie('QDS_AGENT_ORGAN_INFO_'+document.domain);
      base.clearCookie('QDS_COOKIE_'+document.domain);
      this.$router.push({path: '/login'});
    },
    // logo 点击
    handleClickLogo() {
      if (this.$route.path == '/home' || this.$route.path == '/') {
        this.$router.go(0);
      } else {
        this.$router.push({path: '/home', query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
      }
    },
    // 工作台点击
    handleOperatorPlatform() {
      if (this.$route.path == '/home' || this.$route.path == '/') {
        this.$router.go(0);
      } else {
        this.$router.push({path: '/home', query: {fromPath: this.isClient==1 ? 'qdsClient' : 'PC'}});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-dropdown-menu__item{
    padding-right: 50px;
    &:not(.is-disabled):hover {
      background-color: #ecf5ff;
      color: #606266;
    }
    .drop-icon{
      width: 20px;
      margin-right: 14px;
      vertical-align: middle;
    }
  }
  .header {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-bottom: solid 1px rgba(113, 114, 119, 0.2);
    box-shadow: 0px 0px 6px 0px rgba(11, 17, 60, 0.1);
    .header-cont {
      display: flex;
      flex-direction: row;
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      .header-logo {
        width: 75px;
        height: 100%;
        color: $color-text;
        background: url('../../../assets/images/home/logo.png') no-repeat center;
        text-align: center;
        cursor: pointer;
      }
      .header-nav {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .nav-left {
          cursor: pointer;
          // margin-left: 40px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          font-weight: bold;
          font-size: $font-size-large-x;
          color: #222;
        }
        .nav-right {
          position: relative;
          height: 60px;
          margin-right: 4px;
          padding-top: 14px;
          box-sizing: border-box;
          .nav-right-bell {
            display: inline-block;
            width: 24px;
            height: 29px;
            position: relative;
            cursor: pointer;
            img{
              width: 24px;
              height: 29px;
            }
            .badgeItem{
              ::v-deep .el-badge__content{
                background-color: #e90027;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                line-height: 22px;
                padding: 0;
                border: none;
              }
            }
          }
          .nav-right-user{
            margin-left: 30px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            .avatar{
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }
            .username{
              position: relative;
              top: -8px;
              margin-left: 5px;
              color: #666;
              font-size: 14px;
            }
            .more{
              width: 6px;
              margin-left: 2px;
            }
          }
          .signBtn{
            border: 1px solid #ff7200;
            color: #ffffff;
            background: #ff7200;
          }
          .registerBtn{
            color: #ffffff;
            background: #ff7200;
            border-color: #ff7200;
          }
          .nav-right-tip {
            position: absolute;
            left: 3px;
            top: 60px;
            padding: 2px 5px;
            width: 170px;
            font-size: 12px;
            color: #fff;
            background-color: #000;
            white-space: normal;
            border-radius: 8px;
          }
          .nav-right-tip::before {
            position: absolute;
            top: -20px;
            left: 51px;
            content: "";
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-bottom-color: #000;
          }
        }
      }
    }
  }
</style>