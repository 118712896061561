<template>
  <div
    v-loading.fullscreen.lock="homeLoading"
    class="HomeNotifySetting"
  >
    <el-dialog
      v-dialogDrag
      title="通知设置"
      :visible="visible"
      :close-on-click-modal="false"
      width="300px"
      class="notifyDialog"
      center
      top="40vh"
      @close="closeDialog"
    >
      <div class="notifyContent">
        <el-checkbox
          v-model="visitNotice"
          class="checkbox"
        >
          有客户访问主页时通知我
        </el-checkbox>
        <el-checkbox
          v-model="scoreNotice"
          class="checkbox"
        >
          评分变动时晚8点通知我
        </el-checkbox>
      </div>
    
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="loadPlainBtn"
          size="small"
          @click="save()"
        >
          确认保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateMsgStatus } from '@/apis/home/user';
import store from '@/store';
export default {
  name: "HomeNotifySetting",
  props: {
    visible:{
      type: Boolean,
      default: false,
      required: true
    },
    notifyMsgStatus: {
      type: Object,
      required: true
    }
  },
  data(){
    return{
      userId: this.$store.state.userInfo.QDS_LOGIN_INFO_OFFICE.userId,
      visitNotice: false,
      scoreNotice: false,
      homeLoading: false,
    }
  },
  computed: {
    
  },
  watch: {
    notifyMsgStatus(newVal){
      this.visitNotice = newVal.visitNotice;
      this.scoreNotice = newVal.scoreNotice;
    }
  },
  mounted() {
  },
  methods: {
    save(){
      var _this = this;
      this.homeLoading = true;
      var visitStatus = !_this.visitNotice?1:0;
      var scoreStatus = !_this.scoreNotice?1:0;
      let params = {
        userId: this.userId,
        updateMsgData: [
          {
            "msgStatus": visitStatus,
            "msgType": 2
          },
          {
            "msgStatus": scoreStatus,
            "msgType": 3
          }
        ]
      }
      updateMsgStatus(params).then(res=>{
        if(res.code==='00000'){
          let status = {
            visitNotice: this.visitNotice,
            scoreNotice: this.scoreNotice,
          }
          store.commit('userInfo/setNotifyMsgStatus', status);
          this.homeLoading = false;
          setTimeout(() => {
            _this.$emit('update:visible', false);
            _this.$toast.showToast('设置成功');
          }, 2000);
        } else {
          this.$toast.showToast(res.msg);
          _this.$emit('update:visible', false);
          this.homeLoading = false;
        }
      })
    },
    closeDialog(){
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .loadPlainBtn{
    width: 185px;
    color: #FFFFFF;
    background: #ff7200;
    border: 1px solid #ff7200;
    border-radius: 2px;
  }
  .notifyDialog{
    .loadPlainBtn{
      width: 185px;
      color: #FFFFFF;
      background: #FF7200;
      border: 1px solid #FF7200;
      border-radius: 2px;
    }
    ::v-deep .el-dialog__body{
      padding: 10px 25px 25px;
    }
    .notifyContent{
      padding: 0 32px;
      .checkbox{
        margin-top: 15px;
      }
      ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
        color: #606266;
      }
    }
    
  }
</style>