import * as type from '../mutation-types';

const home = {
    namespaced: true,
    state: {
        batchShare: false,
        clearSingleBrand: false,
        allSelect: false, // 底部checkbox是否全选
        selectCount: 0, // 已选多少商标
        selectBrandArr: [], // 已选的商标
        showUnloginDialog: false,
        negotiableFlag: false,
        hideExportBtn: false,
        showShareOriginFlag: false,
        sourceListReqParams: {}
    },
    getters: {},
    mutations: {
        [type.SET_BATCH_SHARE](state, payload) {
            state.batchShare = payload;
        },
        [type.SET_ALL_SELECT](state, payload) {
            state.allSelect = payload;
        },
        [type.SET_SELECT_COUNT](state, payload) {
            state.selectCount = payload;
        },
        [type.SET_SELECTED_BRAND_ARR](state, payload) {
            state.selectBrandArr = payload;
        },
        [type.CLEAR_SINGLE_BRAND](state, payload) {
            state.clearSingleBrand = payload;
        },
        [type.SHOW_UNLOGIN_DIALOG](state, payload) {
            state.showUnloginDialog = payload;
        },
        [type.SET_NEGOTIABLE](state, payload) {
            state.negotiableFlag = payload;
        },
        [type.SET_HIDE_EXPORT_BTN](state, payload) {
            state.hideExportBtn = payload;
        },
        [type.SET_ORIGIN_URL] (state, payload) {
            state.showShareOriginFlag = payload;
        },
        setSorceListReqParams (state, payload) {
            state.sourceListReqParams = payload;
        }
    },
    actions: {}
};

export default home;
