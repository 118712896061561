import Toast from '@/components/g-toast';

let toastObj = {};

toastObj.install = function (Vue) {
  const toastConstructor = Vue.extend(Toast);

  const toast = new toastConstructor();

  toast.$mount(document.createElement('div'));

  document.body.appendChild(toast.$el);

  Vue.prototype.$toast = toast;
}

export default toastObj;
