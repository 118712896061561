const tradeOrder = [
  {
      path: '/tradeOrder',
      name: 'tradeOrder',
      component: () => import(/* webpackChunkName: "home" */ '@/views/tradeOrder/index'),
      meta: {
        name: '权大师_标源商标交易订单列表',
        breadCrumbName: '转让订单管理'
      }
  }
];

export default tradeOrder;
