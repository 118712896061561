const websiteMaintenance = [
  {
      path: '/websiteMaintenance',
      name: 'websiteMaintenance',
      component: () => import(/* webpackChunkName: "websiteMaintenance" */ '@/views/websiteMaintenance/websiteMaintenance'),
      meta: {
        name: '标源-全网维护中',
        breadCrumbName: '全网闲置转让商标查询',
        isNoNav: true
      }
  },
];

export default websiteMaintenance
