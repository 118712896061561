const contract = [
  {
		name: 'contractList',
		path: '/contractList',
		component: ()=>import('@/views/contract/contractList'),
		meta: {
			name: '管理交易合同_全网商标交易查询_标源',
			breadCrumbName: '合同生成管理',
		}
	},
	{
		name: 'contractAdd',
		path: '/contractAdd',
		component: ()=>import('@/views/contract/contractAdd'),
		meta: {
			name: '标源_标源商标交易合同创建',
			breadCrumbName: '生成交易合同'
		}
	},
	{
		name: 'contractPreview',
		path: '/contractPreview',
		component: ()=>import('@/views/contract/contractPreview'),
		meta: {
			name: '预览交易合同_全网商标交易查询_标源',
			breadCrumbName: '交易材料预览'
		}
	},
	{
		name: 'contractMaterial',
		path: '/contractMaterial',
		component: ()=>import('@/views/contract/contractMaterial'),
		meta: {
			name: '查看交易合同_全网商标交易查询_标源',
			breadCrumbName: '生成交易合同'
		}
	}
]
export default contract;