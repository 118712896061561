const orderDetail = [
  {
      path: '/orderDetail',
      name: 'orderDetail',
      component: () => import(/* webpackChunkName: "home" */ '@/views/orderDetail/index'),
      meta: {
        name: '权大师_标源商标交易订单列表',
        breadCrumbName: '转让商标订单'
      }
  }
];

export default orderDetail;
