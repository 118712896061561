<template>
  <div class="select-all-footer">
    <div
      class="footer-cont"
      :style="{width: isClient ? '100%' : ''}"
    >
      <div class="cont-check">
      </div>
      <div class="cont-payload"></div>
      <div class="cont-right">
        <div class="cont-desc">
          <!-- 无底价标源将无法改价，分享用户将展示原价 -->
        </div>
        <div class="cont-checked">
          已选专利 <span>{{ zlSelectCount }}</span> 个
        </div>
        <div
          v-if="!hideExportBtn"
          class="btn-export"
          @click="openExportSettingDialog"
        >
          导出Excel
        </div>
        <div
          class="btn-nextStep"
          @click="handlePriceChange"
        >
          改价分享
        </div>
        <div
          class="btn-export"
          style="margin-left: 20px;margin-right: 0;"
          @click="initHomeStoreState"
        >
          清空已选
        </div>
      </div>
    </div>
    <!-- 扫码改价分享 -->
    <zl-change-price-to-share
      :show-dialog.sync="sharePriceVisible"
      :multi-brand="selectedBrandList"
      @closeShare="handleCloseShareDialog"
    ></zl-change-price-to-share>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import * as type from '@/store/mutation-types';
import zlChangePriceToShare from './zlChangePriceToShare';
import { exportBrandSourceCell,clickRecord } from '@/apis/broker/patentBroker';

/**
 * 底部分享组件
 */
export default {
  components: {
    zlChangePriceToShare,
  },
  data() {
    return {
      sharePriceVisible: false,
      selectedBrandList: [],
      disabledBtn: false,
    }
  },
  computed: {
    ...mapState('patent', {
      zlSelectCount: state => state.zlSelectCount,
      zlSelectPatentArr: state => state.zlSelectPatentArr,
      zlAllSelect: state => state.zlAllSelect,
      hideExportBtn: state => state.zlHideExportBtn
    }),
    
    ...mapState('userInfo', {
      userInfo: state => state.QDS_LOGIN_INFO_OFFICE,
      isClient: state => state.isClient
    }),
  },
  watch: {
    
  },
  methods: {
    saveClickRecord(projectCode,projectName,remark){
      if(!this.userInfo.userId){return}
      var params = {
        userIdStr: this.userInfo.userId || '0',
        projectCode: projectCode,
        projectName: projectName,
        requestUlr: '/zl-select-all',
        platform: 1,
        remark: remark || ''
      }
      clickRecord(params).then(res=>{
      })
    },
    // 打开导出配置弹窗
    openExportSettingDialog(){
      if (this.zlSelectPatentArr.length == 0) {
        this.$toast.showToast('请选择专利后点击');
        return;
      }
      if (this.zlSelectPatentArr.length > 600) {
        this.$toast.showToast('最多选择600个专利');
        return;
      }
      this.exportExcel();
    },
    exportExcel(){
      var that = this;
      console.log(this.zlSelectPatentArr);
      let esIds = [];
      this.zlSelectPatentArr.forEach(item=>{
        esIds.push(item.id);
      })
      this.$notify({
        title: "数据处理中",
        message: "即将下载...",
        type: "success"
      });
      this.disabledBtn = true;
      let params = {
        esIds: esIds,
      }
      this.handleLastedSelectBrandInfo();
      this.saveClickRecord(50032, 'PC导出excel');
      exportBrandSourceCell(params).then(res=>{
        that.disabledBtn = false
        if(res.code=='00000'){
          sessionStorage.removeItem('lastedZlSelectPatentInfo_'+document.domain);
          if(res.data){
            window.open(`https://tm-files.oss-cn-beijing.aliyuncs.com/${res.data}`)
          }
        } else{
          that.$toast.showToast(res.msg);
        }
      })
    },
    initHomeStoreState() {
      this.$store.commit(`patent/${type.SET_ZL_BATCH_SHARE}`, false);
      this.$store.commit(`patent/${type.SET_ZL_SELECT_COUNT}`, 0);
      this.$store.commit(`patent/${type.SET_ZL_ALL_SELECT}`, false);
      this.$store.commit(`patent/${type.SET_SELECTED_PATENT_ARR}`, []);
      sessionStorage.removeItem('lastedZlSelectPatentInfo_'+document.domain);
    },
    handleLastedSelectBrandInfo(){
      let lastedSelectBrandInfo = {};
      if(this.zlSelectPatentArr && this.zlSelectPatentArr.length > 0){
        lastedSelectBrandInfo = {
          SET_SELECTED_BRAND_ARR: this.zlSelectPatentArr,
          lastedUserIdStr: this.userInfo.userId
        }
      }
      sessionStorage.setItem('lastedZlSelectPatentInfo_'+document.domain, JSON.stringify(lastedSelectBrandInfo));
    },
    // 改价分享按钮点击
    handlePriceChange() {
      if (this.zlSelectPatentArr.length == 0) {
        this.$toast.showToast('请选择要改价的专利');
        return;
      }
      if (this.zlSelectPatentArr.length > 600) {
        this.$toast.showToast('最多选择600个专利');
        return;
      }
      this.sharePriceVisible = true;
      this.selectedBrandList = cloneDeep(this.zlSelectPatentArr);
      this.handleLastedSelectBrandInfo();

    },
    // 关闭改价分享弹窗
    handleCloseShareDialog() {
      this.sharePriceVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .select-all-footer {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    height: 80px;
    z-index: 999;
    border-top: 1px solid #e2e3e8;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    .footer-cont {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 1240px;
      height: 36px;
      margin: 0 auto;
      transform: translateY(22px);
      padding: 0 20px;
      box-sizing: border-box;
      .cont-check {
          color: #222;
          font-size: $font-size-medium;
      }
      .cont-payload {
        flex: 1;
      }
      .cont-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .cont-desc {
          padding-left: 20px;
          color: #999;
          background: url('@/assets/images/footer/icon-warning.png') no-repeat 0 center;
          background-size: 12px;
          font-size: $font-size-medium;
          margin-right: 20px;
        }
        .cont-checked {
          color: #222;
          font-size: $font-size-medium;
          padding-bottom: 4px;
          margin-right: 20px;
          span {
            color: $color-text;
            font-size: $font-size-large-x;
          }
        }
        .btn-export {
          width: 140px;
          height: 36px;
          line-height: 36px;
          font-size: 15px;
          border-radius: 0;
          background: #fff;
          border: 1px solid #FF9400;
          color: #FF9400;
          cursor: pointer;
          margin-right: 20px;
        }
        .btn-nextStep {
          width: 140px;
          height: 36px;
          line-height: 36px;
          font-size: 15px;
          border-radius: 0;
          background: #FF9400;
          border: 1px solid #FF9400;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
</style>
