import * as type from '../mutation-types';

const broker = {
    namespaced: true,
    state: {
        brokerBatch: false // 商标经纪人的批量操作标识
    },
    getters: {},
    mutations: {
        [type.SET_BROKER_BATCH](state, payload) {
            state.brokerBatch = payload;
        }
    },
    actions: {}
};

export default broker;
